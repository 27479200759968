import React from 'react';
import {
  Page,
  Document,
  Image,
  StyleSheet,
  View,
  Text
} from '@react-pdf/renderer';
import InvoiceTitle from './RapportTitle';
import RapportClient from './RapportClient';
import RapportNo from './RapportNo';
import RapportItemsTable from './RapportItemsTable';
import RapportFin from './RapportFin';
import logo from '../../img/ascorel.jpg';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    paddingBottom: 50,
    flexDirection: 'column'
  },
  logo: {
    // width: 235,
    width: 115,
    // height: 80,
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    // flex: 1,
    flexDirection: 'row',
    paddingBottom: 10
  },
  leftColumn: {
    flexDirection: 'column',
    width: 60,
    paddingRight: 10
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 25,
    left: 10,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

const Rapport = ({ rapport }) => (
  <Document
    author="ascorel"
    keywords="ascorel grue"
    subject="ASCOREL Fiche grue"
  >
    <Page style={styles.page} wrap>
      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <Image style={styles.logo} src={logo} />
        </View>
        <InvoiceTitle title="Fiche grue - Liste materiel" />
      </View>
      <RapportNo rapport={rapport} />
      <RapportClient rapport={rapport} />
      <RapportItemsTable size="A4" rapport={rapport} />
      <RapportFin rapport={rapport} />
      <Text
        style={styles.footer}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default Rapport;
