import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginTop: 10
  },
  reportTitle: {
    color: '#003ea8',
    letterSpacing: 4,
    fontSize: 18,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
});

const RapportTitle = ({ title }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);

export default RapportTitle;
