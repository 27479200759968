import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './RapportTableHeader';
import InvoiceTableRow from './RapportTableRow';
import InvoiceTableBlankSpace from './RapportTableBlankSpace';

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#000000'
  }
});

const RapportItemsTable = ({ rapport }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow
      items={rapport.fichegrue.bom}
      options={rapport.options}
      nomcom={rapport.fichegrue.nomenclature_com}
    />
    {/* <InvoiceTableBlankSpace rowsCount={tableRowsCount - rapport.items.length} /> */}
  </View>
);

export default RapportItemsTable;
