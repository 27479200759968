import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from '../../img/ascorel.png';
import { sendLinkRestPwd } from '../../actions/user';
import Card from 'react-bootstrap/Card';
import { GrCodepen } from 'react-icons/gr';
import { MdOutgoingMail } from 'react-icons/md';
import Form from 'react-bootstrap/Form';

const VerifyEmailResetPassword = ({ sendLinkRestPwd }) => {
  const [sent, setSent] = useState(false);

  const [formData, setFormData] = useState({
    email: ''
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setSent(true);
    sendLinkRestPwd(formData);
  };

  return (
    <section className="container">
      <Fragment>
        {/* <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Mot de passe
            </h4> */}
        <div className="parent-flex">
          <div>
            <img src={logo} alt="logo" height="100" />
          </div>
        </div>
        <div>
          {!sent ? (
            <Fragment>
              <Card className="card-style-activate">
                <GrCodepen
                  size={96}
                  className="icon-center my-3"
                  color="white"
                />
                <Card.Body className="col-6">
                  <Card.Title className="card-header-color-title-activate text-center">
                    Mot de passe oublié ?
                  </Card.Title>
                  <Card.Title className="card-header-color-title-activate text-center">
                    Entrez votre adresse email ci-dessous pour recevoir un lien
                    de reinitialisation du mot de passe.
                  </Card.Title>
                  <Card.Text
                    className="card-header-color text-center"
                    style={{ marginTop: '5rem' }}
                  >
                    <form className="form" onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => onChange(e)}
                        />
                        <small className="form-text-white">
                          Adresse email communiquée lors de l'inscription.
                        </small>
                      </div>
                      <input
                        type="submit"
                        className="btn btn-light"
                        value="Envoyer"
                      />
                    </form>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Fragment>
          ) : (
            <Fragment>
              <Card className="card-style-activate">
                <MdOutgoingMail
                  size={96}
                  className="icon-center my-3"
                  color="white"
                />
                <Card.Body>
                  <Card.Title className="card-header-color-title-activate text-center">
                    L'email sera envoyé dans quelques minutes. Verifier votre
                    dossier Spam si ce n'est pas le cas.
                  </Card.Title>
                </Card.Body>
              </Card>
            </Fragment>
          )}
        </div>
      </Fragment>
    </section>
  );
};

VerifyEmailResetPassword.propTypes = {
  sendLinkRestPwd: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  sendLinkRestPwd
})(VerifyEmailResetPassword);
