import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ItemListePieces from './ItemListePieces';

const DetailFicheGrueBom = ({ fichegrue, fichegrue: { bom }, options }) => {
  // if (options.length !== 0) console.log(options[0].util);

  const optionsPDFDetail = (list) => {
    return list.length > 0 ? (
      list.map((elt) =>
        elt.piece === null ? (
          <div></div>
        ) : (
          <tr>
            <ItemListePieces eltBom={elt} />
          </tr>
        )
      )
    ) : (
      <div></div>
    );
  };

  return (
    <div style={{ marginTop: 50 }}>
      <h4 className="p-1  bg-ascorel text-white text-center">Liste materiel</h4>
      <table
        className="table table-striped table-light table-header-group"
        style={{ marginTop: 20 }}
        border="1"
      >
        <thead>
          <tr className="title-td">
            <td id="th1" width="10px">
              Seq
            </td>
            <td className="text-center" id="th1">
              Designation
            </td>
            <td className="text-center" id="th2">
              Commentaire
            </td>
            <td className="text-center" id="th3">
              Reference X3
            </td>
            <td className="text-center" id="th3" width="10px">
              Qté
            </td>
            <td className="text-center" id="th4">
              P.J
            </td>
            <td className="text-center" id="th5">
              {' '}
            </td>
            <td className="text-center" id="th6">
              Var.
            </td>
          </tr>
        </thead>
        <tbody>
          {fichegrue.nomenclature_com !== undefined &&
          fichegrue.nomenclature_com.length > 0 ? (
            <tr className="ssection-td">
              <td colspan="12">
                Nomenclature com. : {fichegrue.nomenclature_com}
              </td>
            </tr>
          ) : (
            <div></div>
          )}
          <tr className="ssection-td">
            <td colspan="12">CPU/Interface</td>
          </tr>
          {bom.length > 0 ? (
            bom.map((elt, index) =>
              elt.piece === null || elt.seq > 110 ? (
                <div></div>
              ) : (
                <tr>
                  <ItemListePieces eltBom={elt} affVariante={true} />
                </tr>
              )
            )
          ) : (
            <div>BOM vide :(...</div>
          )}
          {/* Orientation a partir de seq 120 */}
          {bom.filter(
            (item) =>
              item.seq < 180 && (item.seq >= 120) & (item.piece !== null)
          ).length > 0 ? (
            <tr className="ssection-td">
              <td id="th1" colspan="12">
                Orientation
              </td>
            </tr>
          ) : (
            <div></div>
          )}
          {bom.length > 0 ? (
            bom.map((elt, index) =>
              elt.piece === null || elt.seq > 170 || elt.seq < 120 ? (
                <div></div>
              ) : (
                <tr>
                  <ItemListePieces eltBom={elt} affVariante={true} />
                </tr>
              )
            )
          ) : (
            <div>BOM vide :(...</div>
          )}
          {/* Distribution a partir de seq 180 */}
          {bom.filter(
            (item) =>
              item.seq <= 220 && (item.seq >= 180) & (item.piece !== null)
          ).length > 0 ? (
            <tr className="ssection-td">
              <td colspan="12">Distribution</td>
            </tr>
          ) : (
            <div></div>
          )}
          {bom.length > 0 ? (
            bom.map((elt, index) =>
              elt.piece === null || elt.seq < 180 || elt.seq > 220 ? (
                <div></div>
              ) : (
                <tr>
                  <ItemListePieces eltBom={elt} affVariante={true} />
                </tr>
              )
            )
          ) : (
            <div>BOM vide :(...</div>
          )}
          {/* Divers a partir de seq 180 */}
          {bom.filter((item) => (item.seq > 230) & (item.piece !== null))
            .length > 0 ? (
            <tr className="ssection-td">
              <td colspan="12">Divers</td>
            </tr>
          ) : (
            <div></div>
          )}
          {bom.length > 0 ? (
            bom.map((elt, index) =>
              elt.piece === null || elt.seq < 230 ? (
                <div></div>
              ) : (
                <tr>
                  <ItemListePieces eltBom={elt} affVariante={true} />
                </tr>
              )
            )
          ) : (
            <div>BOM vide :(...</div>
          )}
          {/* OPTIONS */}
          {options.length > 0 ? (
            options.map((elt, index) =>
              elt.util === true ? (
                <Fragment>
                  <tr className="ssection-td">
                    <td colspan="1">Option</td>
                    <td id="th2" colspan="1">
                      {elt.nom}
                    </td>
                    <td id="th3" colspan="1">
                      {elt.commentaire}
                    </td>
                    {elt.refx3.length >= 9 ? (
                      <td id="th4" colspan="5">
                        {elt.refx3}
                      </td>
                    ) : (
                      <td id="th8" colspan="5"></td>
                    )}
                  </tr>
                  {optionsPDFDetail(elt.bom)}
                </Fragment>
              ) : (
                <div></div>
              )
            )
          ) : (
            <div></div>
          )}
        </tbody>
      </table>
    </div>
  );
};

DetailFicheGrueBom.propTypes = {
  fichegrue: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default DetailFicheGrueBom;
