import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createFichegrue } from '../../actions/fichegrue';
import { connect } from 'react-redux';
import { FaFlag } from 'react-icons/fa6';

const AffichageDrapeau = ({
  createFichegrue,
  auth,
  valide,
  ID,
  setRefresh
}) => {
  const navigate = useNavigate();

  function affichageAdmin(couleur) {
    return (
      <div className="parent-flex">
        <div className="child-left-auto">
          <div className="text-right">
            <button
              type="button"
              className="btn"
              onClick={changementEtatValide}
              style={{ marginRight: '0rem' }}
            >
              <FaFlag size={20} style={{ color: couleur }} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function changementEtatValide() {
    const dataValide = {
      valide: !valide
    };
    // console.log(ID);
    //Si nous sommes sur une page qui affiche un drapeau de validation, alors appeler createfichegrue en lui fournissant les données à modifier dataValide et lui disant qu'on est sur cette page.
    await createFichegrue(ID, dataValide, navigate, true);
    // permet de rafraîchir la page une fois que createfichegrue aura été exécuté.
    setRefresh();
  }

  return (
    <>
      {auth.user.rights === 'admin' ? (
        <>
          {valide === false
            ? affichageAdmin('red')
            : valide === true
            ? affichageAdmin('#28a745')
            : null}
        </>
      ) : auth.user.rights === 'user' ? (
        <>
          {valide === true ? (
            <div className="parent-flex">
              <div className="child-left-auto">
                <div className="text-right">
                  <h4>
                    <span
                      class="badge badge-pill badge-success p-2"
                      style={{ marginRight: 0 }}
                    >
                      Validée
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

AffichageDrapeau.propTypes = {
  auth: PropTypes.object.isRequired,
  createFichegrue: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  createFichegrue
})(AffichageDrapeau);
