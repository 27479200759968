import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { MdPlaylistAdd } from 'react-icons/md';
import { clearPiece } from '../../actions/piece';
import { useNavigate } from 'react-router-dom';

const RecherchePiece = ({ clearPiece, auth }) => {
  useEffect(() => {
    clearPiece();
  }, [clearPiece]);

  const [textRecherche, setTextRecherche] = useState('');

  const navigate = useNavigate();

  const routeChange = () => {
    let path =
      textRecherche !== ''
        ? `/resultatspiece/${textRecherche.replace('/', '|||')}/`
        : `/resultatspiece/ALL_PIECES/`;
    navigate(path);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        routeChange();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [textRecherche]);

  return (
    <section className="container">
      <div>
        <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
          Recherche pièce
        </h4>
        <div class="row text-center" style={{ marginTop: 30 }}>
          <div class="row  col-xs-6" style={{ margin: 10 }}>
            <div style={{ margin: 10 }}>
              <input
                type="text"
                className="input form-control center-block"
                value={textRecherche}
                onChange={(e) => setTextRecherche(e.target.value)}
                placeholder="Entrez texte recherché"
              />
            </div>
          </div>
          <div className=" text-center col-xs-6" style={{ margin: 10 }}>
            <div className="row">
              <div className="col text-center">
                <button
                  style={btnStyle}
                  className="btn btn-primary btn-lg center-block"
                  onClick={routeChange}
                >
                  Rechercher piece <FaSearch />
                </button>

                <Link to="/creationpiece/0" className="text-navbar">
                  <button
                    style={btnStyle}
                    className="btn btn-primary btn-lg center-block"
                  >
                    Creer piece <MdPlaylistAdd />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

RecherchePiece.propTypes = {
  clearPiece: PropTypes.func.isRequired,
  piece: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  piece: state.piece
});

export default connect(mapStateToProps, {
  clearPiece
})(RecherchePiece);

const btnStyle = {
  margin: '8px',
  padding: '8px'
};
