import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';

const UserItem = ({
  user: { _id, name, email, rights, entity, date, enabled, lastSeen }
}) => {
  return (
    <Fragment>
      <tr>
        <td width="10px">
          {enabled ? (
            <div className="text-center align-middle badge badge-pill badge-success p-2 mb-2 ">
              Activé
            </div>
          ) : (
            <div className="badge badge-pill badge-danger  p-2 mb-2 ">
              Désactivé
            </div>
          )}
        </td>
        <td
          className="text-center align-middle "
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          <Link to={`/user/${_id}`}>
            <span className="text-center align-middle">{email}</span>
          </Link>
        </td>
        <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {name}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {rights}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          width="100"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {lastSeen ? (
            <Moment format=" DD/MM/YYYY hh:mm:ss">
              {moment(lastSeen).local()}
            </Moment>
          ) : (
            <div> --- </div>
          )}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          width="100"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          <Moment format=" DD/MM/YYYY hh:mm:ss">{moment(date).local()}</Moment>
        </td>
      </tr>
    </Fragment>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserItem;
