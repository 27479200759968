import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { GoDotFill } from 'react-icons/go';

const borderColor = '#000000';

const styles = StyleSheet.create({
  commentaire: {
    width: '25%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    fontSize: 8
  },
  nom: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    fontSize: 8
  },
  refx3: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    fontSize: 8
  },
  var: {
    width: '5%',
    textAlign: 'center',
    // paddingRight: 4,
    fontSize: 8
  },
  seq: {
    width: '5%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    // paddingRight: 8,
    fontSize: 8
  }
});

const ItemRapportTableRow = ({ item }) => {
  if (item === undefined || item === null) {
    return (
      <Fragment>
        <Text style={styles.seq}></Text>
        <Text style={styles.nom}></Text>
        <Text style={styles.commentaire}></Text>
        <Text style={styles.refx3}></Text>
        <Text style={styles.seq}></Text>
        <Text style={styles.var}></Text>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Text style={styles.seq}>{item.seq}</Text>
        <Text style={styles.nom}>{item.piece.nom}</Text>
        {item.piece.commentaire && item.piece.commentaire.length > 0 ? (
          <Text style={styles.commentaire}>{item.piece.commentaire}</Text>
        ) : (
          <Text style={styles.commentaire}> </Text>
        )}
        {/* <Text style={styles.commentaire}>{item.piece.commentaire}</Text> */}
        <Text style={styles.refx3}>{item.piece.refx3}</Text>
        <Text style={styles.seq}>{item.qte}</Text>
        {item.variantepossible && item.variantepossible === true ? (
          <Text style={styles.var}> v</Text>
        ) : (
          <Text style={styles.var}></Text>
        )}
      </Fragment>
    );
  }
};

export default ItemRapportTableRow;
