import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPieceById, deletePiece, createPiece } from '../../actions/piece';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { MdRestoreFromTrash } from 'react-icons/md';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import Spinner from '../layout/Spinner';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import Moment from 'react-moment';
import 'moment/locale/fr';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useParams, useNavigate } from 'react-router-dom';

const Buffer = require('buffer/').Buffer;

const DetailPiece = ({
  getPieceById,
  deletePiece,
  createPiece,
  piece: { piece, loadingpiece },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPieceById(id);
  }, [getPieceById, id]);

  const EffacementPiece = () => {
    if (window.confirm('Etes vous sur de vouloir supprimer cete pièce ?')) {
      // deletePiece(piece._id);
      setaction(true);
      createPiece(piece._id, { deleted: true }, navigate);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  };

  const RestaurerPiece = () => {
    if (window.confirm('Etes vous sur de vouloir restaurer cette pièce ?')) {
      // deletePiece(piece._id);
      setaction(true);
      createPiece(piece._id, { deleted: false }, navigate);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  };

  const [action, setaction] = useState(false);

  if (loadingpiece || piece === null || action) {
    return <Spinner />;
  } else {
    // console.log(testOptions);
    const {
      _id,
      nom,
      refx3,
      commentaire,
      typemc600,
      piecesjointes,
      seqpossible,
      datecreation,
      datemodification,
      deleted,
      vignette
    } = piece;

    let base64ImageString;
    if (vignette) {
      base64ImageString = Buffer.from(
        vignette.img.data.data,
        'binary'
      ).toString('base64');
    }
    var completionPJ =
      (piecesjointes.filter((i) => i.piecejointe.type === 'img').length > 0
        ? 33
        : 0) +
      (piecesjointes.filter((i) => i.piecejointe.type === 'txt').length > 0
        ? 33
        : 0) +
      (piecesjointes.filter((i) => i.piecejointe.type === 'pdf').length > 0
        ? 33
        : 0);
    completionPJ = completionPJ === 0 ? 10 : completionPJ;
    completionPJ = completionPJ === 99 ? 100 : completionPJ;

    const variantPJ =
      completionPJ <= 33
        ? 'danger'
        : completionPJ <= 66
        ? 'warning'
        : 'success';

    return (
      <section className="container">
        <Fragment>
          <Card>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Détails fiche
            </h4>
            {deleted ? (
              <div style={headStyleDeleted}>Pièce SUPPRIMEE</div>
            ) : (
              <div></div>
            )}
            <div class="row col-12">
              <div class="col-10" style={{ marginBottom: 1 }}>
                <div style={{ marginLeft: 20, marginTop: 20 }}>
                  {nom !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Nom :</b>
                      </span>{' '}
                      <span
                        className="text-center text-primary"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {nom}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {refx3 !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Référence X3 :</b>
                      </span>{' '}
                      <span
                        className="text-center text-primary"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {refx3}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {seqpossible !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Sequences possibles :</b>
                      </span>{' '}
                      <span
                        className="text-center text-primary"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {seqpossible.join(' / ')}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {typemc600 !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Type MC :</b>
                      </span>{' '}
                      <span
                        className="text-center text-primary"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {typemc600}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {commentaire !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Commentaire :</b>
                      </span>{' '}
                      <span
                        className="text-center text-primary"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {commentaire}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {datecreation !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Date création :</b>
                      </span>{' '}
                      <Moment
                        className="text-center text-primary"
                        format="DD/MM/YYYY HH:mm"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {moment.utc(datecreation)}
                      </Moment>
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {datemodification !== undefined ? (
                    <p>
                      <span className="text-center ">
                        <b>Date modification :</b>
                      </span>{' '}
                      <Moment
                        className="text-center text-primary"
                        format="DD/MM/YYYY HH:mm"
                        style={{
                          textDecorationLine: deleted ? 'line-through' : ''
                        }}
                      >
                        {moment.utc(datemodification)}
                      </Moment>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <div class="col-2">
                {vignette ? (
                  <div style={{ marginTop: 10 }} className="image-preview">
                    <img
                      className="vignette-detail"
                      src={`data:${vignette.img.contentType};base64,${base64ImageString}`}
                      alt="Preview"
                    />
                  </div>
                ) : (
                  <div> Pas de vignette</div>
                )}
              </div>
            </div>
          </Card>

          <div style={{ marginTop: 50 }} className="card">
            <div style={headStyle} className="card-header">
              Pièces jointes :
            </div>
            <td
              class="text-center align-middle"
              id="pj"
              style={{
                textDecorationLine: deleted ? 'line-through' : ''
              }}
            >
              <ProgressBar
                now={completionPJ}
                label={`${completionPJ}%`}
                variant={variantPJ}
              />
            </td>
            <ListePiecesJointes tailleTexte="normal" listPJ={piecesjointes} />
          </div>

          <div style={{ marginTop: 50 }} className="row ">
            <div className="col-12 text-center ">
              {!deleted ? (
                <div>
                  <Link to={`/creationpiece/${_id}`}>
                    <button
                      // className="btn btn-warning btn-lg float-rigth"
                      className="btn btn-primary btn-lg"
                    >
                      Modifier piece <MdOutlineModeEditOutline />
                    </button>
                  </Link>

                  <button
                    // className="btn btn-warning btn-lg float-rigth"
                    className="btn btn-warning btn-lg"
                    onClick={EffacementPiece}
                  >
                    Effacer piece <MdDelete />
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-success btn-lg"
                  onClick={RestaurerPiece}
                >
                  Restaurer piece <MdRestoreFromTrash />
                </button>
              )}
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

DetailPiece.propTypes = {
  auth: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  deletePiece: PropTypes.func.isRequired,
  createPiece: PropTypes.func.isRequired,
  getPieceById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  piece: state.piece,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPieceById,
  deletePiece,
  createPiece
})(DetailPiece);

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
const headStyleDeleted = {
  fontSize: '20px',
  background: '#dc3545',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
moment.locale('fr');
