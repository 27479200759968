import {
  GET_OPTION,
  OPTION_ERROR,
  CLEAR_OPTION,
  UPDATE_OPTION,
  GET_OPTIONS
} from '../actions/types';

const initialState = {
  option: null,
  options: [],
  loadingopt: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OPTION:
    case UPDATE_OPTION:
      return {
        ...state,
        option: payload,
        loadingopt: false
      };
    case GET_OPTIONS:
      return {
        ...state,
        options: payload,
        loadingopt: false
      };
    case OPTION_ERROR:
      return {
        ...state,
        error: payload,
        loadingopt: false,
        option: null
      };
    case CLEAR_OPTION:
      return {
        ...state,
        option: null,
        options: [],
        loadingopt: true
      };

    default:
      return state;
  }
}
