import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_USER,
  GET_USERS,
  USER_ERROR,
  UPDATE_USER,
  CLEAR_USER,
  USER_DELETED
} from './types';

// Get all users
export const getUsers = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER });

  try {
    const res = await axios.get('/api/user');

    dispatch({
      type: GET_USERS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get User by ID
export const getUserById = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/id/${userId}`);

    dispatch({
      type: GET_USER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update User
export const createUser = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(
      id ? `/api/user/id/${id}` : '/api/user',
      formData,
      config
    );

    dispatch({
      type: UPDATE_USER,
      payload: res.data
    });

    dispatch(setAlert(id ? 'user updated' : 'user created', 'success'));

    // if (!edit) {
    //   history.push('/dashboard');
    // }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete user
export const deleteUser = (userId) => async (dispatch) => {
  if (
    window.confirm('Etes vous sur ? Cette opération ne peut PAS être annulée !')
  ) {
    try {
      await axios.delete(`/api/user/${userId}`);

      dispatch({ type: USER_DELETED });

      dispatch(
        setAlert('Le compte a été effacé de manière definitive', 'danger')
      );
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};

// Activate user
export const activateUser = (id, token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(
      `/api/user/activate/${id}/${token}`,
      null,
      config
    );

    dispatch({
      type: UPDATE_USER,
      payload: res.data
    });

    dispatch(setAlert('user activated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// sendLinkRestPwd user
export const sendLinkRestPwd = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    await axios.post('/api/user/sendlinkresetpwd', formData, config);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// updateForgottenPwd user
export const updateForgottenPwd = (id, token, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await axios.post(
      `/api/user/updateforgottenpwd/${id}/${token}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_USER,
      payload: res.data
    });

    dispatch(setAlert('password updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: USER_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
