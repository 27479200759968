import React, { Fragment } from 'react';
import { MdDelete } from 'react-icons/md';
import { GrAdd } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const ElementNomenclature = ({
  sequence,
  listePieces,
  onRemoveVariante,
  onDeletePiece,
  OnChangeQte,
  OnChangeSeq,
  qte,
  listSequences
}) => {
  return (
    <Fragment>
      <td className="vertcentered">
        <Form.Select
          aria-label="selection sequence"
          className="select"
          name="qte"
          value={sequence}
          onChange={OnChangeSeq}
        >
          <option value="0">Seq.</option>
          {listSequences.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      </td>
      <td className="vertcentered ">
        <table>
          <tbody>
            {listePieces.length > 0 ? (
              listePieces.map((elt, index) =>
                elt === null ? (
                  <div></div>
                ) : (
                  <tr key={elt._id}>
                    <td
                      className="vertcentered"
                      width="600"
                      style={{
                        textDecorationLine: elt.flaggedForRemoval
                          ? 'line-through'
                          : ''
                      }}
                    >
                      <Link to={`/piece/${elt._id}`}>{elt.nom}</Link>
                    </td>
                    <td
                      className="vertcentered"
                      width="200"
                      style={{
                        textDecorationLine: elt.flaggedForRemoval
                          ? 'line-through'
                          : ''
                      }}
                    >
                      {elt.refx3}
                    </td>
                    <td className=" vertcentered">
                      {elt.flaggedForRemoval === true ? (
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            onRemoveVariante(sequence / 10 - 1, index, false)
                          }
                        >
                          <GrAdd />
                        </button>
                      ) : (
                        <button
                          className="btn btn-warning"
                          onClick={() =>
                            onRemoveVariante(sequence / 10 - 1, index, true)
                          }
                        >
                          <MdDelete />
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )
            ) : (
              <Fragment></Fragment>
            )}
          </tbody>
        </table>
      </td>
      <td className="vertcentered">
        <Form.Control
          type="number"
          name="qte"
          value={qte}
          onChange={OnChangeQte}
          placeholder="qté"
        />
      </td>
      <td className="vertcentered">
        <button className="btn btn-warning" onClick={onDeletePiece}>
          <MdDelete />
        </button>
      </td>
    </Fragment>
  );
};

export default ElementNomenclature;
