import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { GiCrane } from 'react-icons/gi';
import { MdViewList } from 'react-icons/md';
import { MdAccountCircle } from 'react-icons/md';
import { GiScrew } from 'react-icons/gi';
import { GiSherlockHolmes } from 'react-icons/gi';
import { FaUser } from 'react-icons/fa';
import { setAlert } from '../../actions/alert';
import { AiOutlineLogout } from 'react-icons/ai';

const Navbar = ({ auth: { isAuthenticated, loading, user }, logout }) => {
  const DeconnexionUtilisateur = (logout, setAlert) => {
    if (window.confirm('Etes vous sur de vouloir vous deconnecter ?')) {
      setAlert('Vous avez été deconnecté', 'danger');
      logout();
    }
  };
  const authLinks = (
    <ul>
      <li
        style={{
          marginTop: '0.3rem'
        }}
      >
        <Link to="/recherche/" className="text-navbar">
          <GiCrane /> <span className="hide-sm">Fiches grue</span>
        </Link>
      </li>
      <li
        style={{
          marginTop: '0.3rem'
        }}
      >
        <Link to="/recherchepiece/" className="text-navbar">
          <GiScrew /> <span className="hide-sm">Pièces</span>
        </Link>
      </li>
      <li
        style={{
          marginTop: '0.3rem'
        }}
      >
        <Link to="/rechercheoption/" className="text-navbar">
          <GiSherlockHolmes /> <span className="hide-sm">Options</span>
        </Link>
      </li>
      <li
        style={{
          marginTop: '0.3rem'
        }}
      >
        <Link to="/listnombase/" className="text-navbar">
          <MdViewList /> <span className="hide-sm">Nom. Com.</span>
        </Link>
      </li>

      {user && (user.rights === 'admin' || user.rights === 'admin_entity') ? (
        <li
          style={{
            marginTop: '0.3rem'
          }}
        >
          <Link to="/users" className="text-navbar ">
            <FaUser
              style={{
                marginRight: '0.2rem',
                marginBottom: '0.2rem'
              }}
            />{' '}
            <span className="hide-sm">Utilisateurs</span>
          </Link>
        </li>
      ) : (
        <Fragment></Fragment>
      )}
      {user && (
        <li
          style={{
            marginTop: '0.3rem'
          }}
        >
          <Link to={`/user/${user._id}`} className="text-navbar">
            {' '}
            <MdAccountCircle
              style={{
                marginRight: '0.2rem',
                marginBottom: '0.2rem'
              }}
            />{' '}
            <span className="hide-sm">{user && user.name}</span>
          </Link>
        </li>
      )}
      {user && (
        <li
          style={{
            marginTop: '0.3rem'
          }}
        >
          <Link to="/recherche" className="deco">
            <span
              onClick={() => DeconnexionUtilisateur(logout, setAlert)}
              className="text-navbar"
            >
              <AiOutlineLogout
                style={{
                  marginRight: '0.2rem',
                  marginBottom: '0.2rem'
                }}
                size={16}
              />{' '}
              <span className="hide-sm">Deconnexion</span>
            </span>
          </Link>
        </li>
      )}

      {/* <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li> */}
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/register">Nouveau compte</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  console.log('type app  : ' + process.env.REACT_APP_TYPE_SERVEUR);
  console.log('user navbar  : ' + user);

  return (
    <nav
      className={
        process.env.REACT_APP_TYPE_SERVEUR === 'staging'
          ? 'navbar bg-danger'
          : 'navbar bg-ascorel'
      }
    >
      <ul>
        <li>
          <GiCrane size={30} />
          &nbsp;{' '}
        </li>
        <li>
          <h2 className="titre-navbar">
            <Link to="/">
              <i />
              {process.env.REACT_APP_TYPE_SERVEUR === 'staging'
                ? 'BG staging'
                : 'Base Grue'}
            </Link>
          </h2>
        </li>
        {/* <li style={{ marginTop: 20 }}>1.0.0</li> */}
      </ul>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout, setAlert })(Navbar);
