import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IoMdArrowBack } from 'react-icons/io';
import { MdInfoOutline } from 'react-icons/md';
import Accordion from 'react-bootstrap/Accordion';

const CGUacg = ({ auth }) => {
  return (
    <section className="container">
      <h1 className="large text-ascorel">Conditions générales d'utilisation</h1>
      <div className="parent-flex">
        <p className="lead">
          <MdInfoOutline
            size={40}
            color="grey"
            style={{
              marginRight: '1rem'
            }}
          />
          Contrat d'adhésion
        </p>
        {auth && auth.user ? (
          <div className="child-left-auto">
            <Link to={`/user/${auth.user._id}`}>
              <button
                className="btn btn-dark btn-lg"
                style={{
                  marginRight: '0rem',
                  marginBottom: '0.5rem'
                }}
              >
                <IoMdArrowBack
                  color="white"
                  style={{
                    marginRight: '0.2rem',
                    marginBottom: '0.2rem'
                  }}
                />
                Retour Mon compte
              </button>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="cgu-title">
              Politique de protection des données personnelles
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">1. Les données collectées</p>
            <p className="cgu-txt">
              Le présent document détaille la façon dont vos données sont
              protégées. Il s’agit des données personnelles collectées et
              traitées par la société ASCOTRONICS S.A.S. dont le siège social
              est situé : <br />
              <br />
              357 Rue du champ de course
              <br />
              38780 PONT-EVEQUE
              <br />
              FRANCE.
            </p>
            <p className="cgu-subtitle">
              2. Qu’est-ce qu’une donnée personnelle ?
            </p>
            <p className="cgu-txt">
              Les « données à caractère personnel » désignent toute information
              susceptible de vous identifier directement (par ex., votre nom) ou
              indirectement (par ex., par le biais de données pseudonymisées tel
              qu’un identifiant unique). Cela signifie que les données à
              caractère personnel incluent des informations telles que des
              adresses de messagerie, noms d’utilisateur, des contenus générés
              par des utilisateurs, le sexe, la tranche d’âge, le lieu
              d’origine, le profil d’utilisateur (alternant, collaborateur
              d’entreprise, candidat individuel…). Les données à caractère
              personnel sont également susceptibles d’inclure des identifiants
              numériques uniques comme l’adresse IP de votre ordinateur ou
              l’adresse MAC de votre appareil mobile, ainsi que des cookies.
            </p>
            <p className="cgu-subtitle">3. Quelles données sont collectées ?</p>
            <p className="cgu-txt">
              ASCOTRONICS collecte et gère les informations ci-après dans le
              cadre de son activité de suivi d’équipements : <br />
              <br />
              Les informations communiquées par les utilisateurs de la
              plateforme inscrits sur le site : <br />
              <br />• Nom et prénom de l’utilisateur <br />• Adresse email de
              l’apprenant (professionnelle et/ou personnelle selon le cas)
              <br />• Organisation auquel l’utilisateur appartient (société,
              établissement d’enseignement, etc…)
              <br />• Pays d’origine
            </p>
            <p className="cgu-subtitle">
              4. Les raisons de la collecte de ces données ?
            </p>
            <p className="cgu-txt">
              Nous collectons ces données personnelles dans le cadre de notre
              activité de suivi d’équipement notamment pour les finalités
              suivantes : <br />
              <br />
              1. Fournir à nos utilisateurs les prestations de suivi de
              l’équipement ;<br />
              2. Suivre et gérer les données générées par l’équipement au cours
              de son utilisation ; <br />
              3. Permettre de transmettre ces informations à un service
              technique et/ou d’assistance inclus sur la plateforme ou tiers à
              la plateforme ;
              <br />
              4. Recueillir vos commentaires et avis dans le cadre d’enquêtes
              statistiques ; <br />
              Nous pouvons collecter des données personnelles par le biais de
              cookies afin par exemple d’améliorer votre navigation sur notre
              Site ou mesurer son audience. Pour de plus amples informations,
              merci de consulter notre Politique d’utilisation des cookies.
            </p>
            <p className="cgu-subtitle">
              5. De quelle manière les données sont-elles collectées ?
            </p>
            <p className="cgu-txt">
              Vos données personnelles sont collectées de la manière suivante :
              <br />
              <br />
              Lors de votre navigation sur notre/nos site(s) internet.
            </p>
            <p className="cgu-subtitle">
              6. Durée de conservation des données personnelles ?
            </p>
            <p className="cgu-txt">
              Nous mettons en œuvre des politiques de protection des systèmes
              d’information adaptées à la nature des données traitées et à son
              activité, conformément à la règlementation en vigueur. <br />
              <br />
              En notre qualité de responsable de traitement des données
              personnelles et conformément aux règles nationales et européennes
              relatives à la protection des données personnelles (et notamment
              le Règlement Général sur la Protection des Données) nous apportons
              une vigilance extrême concernant le partage des données
              personnelles avec des tiers. <br />
              <br />
              Les données ne sont partagées qu’en tant que de besoin avec les
              sous-traitants (notamment son prestataire d’hébergement
              informatique), les services tiers de support technique sur les
              équipements connecté à la plateforme.
            </p>
            <p className="cgu-subtitle">
              7. Les droits des titulaires des données personnelles
            </p>
            <p className="cgu-txt">
              Les données à caractère personnel sont conservées pendant une
              durée limitée qui n’excède pas la durée nécessaire aux finalités
              du traitement, cette durée n’excède pas 3 ans après la fin de la
              dernière connexion à la plateforme. <br />
              <br />
              Les délais de conservation des données sont portés à la
              connaissance des titulaires des données, et varient selon la
              nature des données, la finalité des traitements, ou les exigences
              légales ou réglementaires.
            </p>
            <p className="cgu-subtitle">8. Modifications de cette Politique</p>
            <p className="cgu-txt">
              Conformément à la règlementation en vigueur, les titulaires des
              données personnelles disposent d’un droit d'accès, d’information
              sur la finalité du traitement, de limitation, de suppression, de
              portabilité, de rectification et d'opposition au traitement de
              leurs données personnelles. <br />
              <br />
              Le titulaire des données peut exercer ces droits en contactant
              Ascotronics : <br />
              <br />• Par email : dpo-rgpd@ascotronics.com
              <br />• Par courrier :
              <br />
              <br />
              ASCOTRONICS
              <br />
              DPO-Référent RGPD
              <br />
              357 Rue du champ de course
              <br />
              38780 PONT-EVEQUE - FRANCE
              <br />
              <br />
              Enfin, vous pouvez introduire une réclamation auprès d’une
              autorité de protection des données personnelles dans l’Etat membre
              de l’Union européenne dans lequel vous résidez, dans celui dans
              lequel vous travaillez ou dans celui où un manquement au droit de
              la protection des données personnelles a eu lieu.
            </p>
            <p className="cgu-subtitle">
              9. Protection et partage des données personnelles
            </p>
            <p className="cgu-txt">
              La présente politique de protection des données sera actualisée en
              tant que de besoin.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="cgu-title">Mentions légales</div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">1. Informations éditeur</p>
            <p className="cgu-txt">
              La présente Plateforme de Service disponible à l’URL
              acg.ascorel.com est éditée par la société ASCOTRONICS S.A.S.
              représentée par Francis Pierre LEMAHIEU en sa qualité de président
              <br />
              <br />
              Siège social :<br />
              <br />
              Rue du Champs de course
              <br />
              Zone Industrielle Montplaisir
              <br />
              38780 Pont-Evèque
              <br />
              FRANCE
            </p>
            <p className="cgu-txt-bold">
              <br />
              Numéro SIREN: 441 607025
              <br />
              N° TVA intracommunautaire : FR 34 441 607 025
              <br />
              <br />
              Contact :{' '}
            </p>
            <p className="cgu-txt">
              <br />• Téléphone: +33 (0)4 74 57 62 63
              <br />• Adresse électronique : mail-informatique@ascorel.com
            </p>
            <p className="cgu-txt-bold">
              Directeur de publication : Francis Pierre LEMAHIEU
            </p>
            <p className="cgu-subtitle">2. Informations hébergeur :</p>
            <p className="cgu-txt">
              Nom : Heroku, Inc
              <br />
              <br />
              Adresse : Heroku, Inc., 1 Market St. Suite 300, San Francisco, CA
              94105
            </p>
            <p className="cgu-subtitle">3. Crédits</p>
            <p className="cgu-txt">
              Conception et réalisation de la plateforme : ASCOREL S.A.
              <br />
              <br />
              Hébergement : Heroku, Inc
              <br />
              <br />
              Photos : Les photos et descriptifs sont non contractuels et donnés
              à titre indicatif.
              <br />
              <br />
              Ascotronics se réserve le droit de les modifier sans préavis.
              <br />
              <br />
              La présente Plateforme et ses mentions légales sont soumises au
              droit français.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="cgu-title">
            <div className="cgu-title">Politique de confidentialité</div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">
              1. Données personnelles pouvant être collectées
            </p>
            <p className="cgu-txt">
              La plateforme de service (abrégée ci-dessous en PFS) peut
              collecter et traiter les données suivantes vous concernant.
            </p>
            <p className="cgu-txt-bold">
              Des données d’identification directe, dont certaines sont
              obligatoires et d’autres facultatives (données de profil que vous
              décidez de renseigner ou non).
            </p>
            <p className="cgu-txt-bold">Données obligatoires :</p>
            <p className="cgu-txt">
              • Identifiant
              <br />• Prénom
              <br />• Nom
              <br />• Adresse de courriel
              <br />• Mot de passe
              <br />• Pays
            </p>
            <p className="cgu-txt">
              Des données liées à l’utilisation propre de l’application (Outil
              électronique connecté à la plateforme, données de mesures, rapport
              de diagnostic, …). Ce sont les données de tracking sur les
              ressources mises à disposition dans la PFS. <br />
              <br />
              Informations détaillées d’équipement électronique : liste des
              outils utilisés par l’utilisateur et les données associés à cet
              équipement{' '}
            </p>
            <p className="cgu-txt-bold">'Rapports :</p>
            <p className="cgu-txt">
              • Toute l’activité des équipements électroniques connectés à la
              PFS. <br />• Toutes les activités liées aux données remontées par
              les équipements connectés à la PFS.
            </p>
            <p className="cgu-txt-bold">Informations de connexion :</p>
            <p className="cgu-txt">
              La plateforme collecte les dates de premier et dernier accès et
              les affiche en clair aux utilisateurs. Elle trace également dans
              des tables, accessibles aux gestionnaires (si l'accès est autorisé
              par l'Administrateur) tous les logins de chaque utilisateur (date,
              heure, durée de connexion s'ils se déconnectent).
            </p>
            <p className="cgu-txt-bold">Divers :</p>
            <p className="cgu-txt">
              La PFS collecte tous les messages publiés sur le site.
            </p>
            <p className="cgu-subtitle">
              2. Utilisations de vos données personnelles
            </p>
            <p className="cgu-txt">
              Vos données ne seront utilisées qu'aux fins utiles au sites de la
              PFS pour : <br />
              <br />• Vous permettre d’accéder à l’ensemble des ressources mises
              à votre disposition <br />• Nous permettre de suivre votre
              activité et celle des équipements connectés à la PFS. <br />•
              Transmettre les informations à des organismes dont l’utilisateur
              dépends sur la PFS : employeur, fournisseur de l’outil de
              diagnostic de la marque, support technique de l’équipement
              technique.
            </p>
            <p className="cgu-subtitle">3. Divulgation de vos données</p>
            <p className="cgu-txt">
              Nous ne communiquerons aucune de vos données à des tiers sans
              votre permission, sans votre consentement sur un formulaire, ou
              sauf indication contraire dans la présente charte de
              confidentialité. <br />
              <br />
              Nous nous réservons la possibilité de divulguer vos informations
              personnelles à des tiers, sans obtenir votre consentement, dans
              les cas suivants : <br />
              <br />• Si nous sommes dans l'obligation de divulguer ou de
              partager vos données personnelles afin de respecter toute
              obligation légale ou de protéger nos droits, nos biens ou la
              sécurité de nos employés, de nos clients ou d'autres personnes.
              Cela comprend l'échange d'informations avec d'autres sociétés et
              organisations à des fins de protection contre la fraude. <br />
              <br />
              Nous nous engageons à ne jamais vendre vos données à des tiers, à
              des fins de marketing.
            </p>
            <p className="cgu-subtitle">4. Stockage de vos données</p>
            <p className="cgu-txt">
              Les données que nous collectons auprès de vous peuvent être
              transférées et stockées dans un lieu situé en dehors de la France,
              mais toujours dans l’Union Européenne. <br />
              <br />A notre niveau, vos données sont traitées dans l'UE (en
              France, au moment de la rédaction). Au niveau de nos partenaires,
              le traitement des données peut également avoir lieu en dehors de
              l'Europe, dans les limites légales.
              <br />
              <br />
              Nous pouvons conserver dans nos archives certaines des
              informations associées à votre compte, notamment à des fins de
              statistiques sur l’activité sur la PFS. La durée de conservation
              de vos données personnelles dépend de la finalité pour laquelle
              nous les collectons et de l'utilisation que nous en faisons.{' '}
              <br />
              <br />
              Nous ne conserverons vos données personnelles que pendant la durée
              strictement nécessaire aux finalités énoncées dans le paragraphe «
              Utilisation de vos données personnelles » ou pour satisfaire à nos
              obligations légales. La période de conservation de vos données
              personnelles est de 3 ans à compter de la date de votre dernière
              activité. Nous pouvons conserver certaines données personnelles
              pendant une durée plus longue, y compris après la fermeture de
              votre compte, si une telle mesure est nécessaire pour remplir nos
              obligations légales ou pour maintenir la sécurité, pour empêcher
              la fraude et les abus et pour défendre ou faire valoir nos droits.
            </p>
            <p className="cgu-subtitle">5. Vos droits</p>
            <p className="cgu-txt">
              Vous avez le droit d'accéder à vos données personnelles, de les
              modifier et de les supprimer. <br />
              <br />
              Si vous souhaitez demander une copie de vos données personnelles,
              la suppression de vos données personnelles ou si vous avez toute
              question liée à ces données, vous pouvez contacter ASCOTRONICS
              S.A.S via l’adresse email suivante : dpo-rgpd@ascotronics.com
            </p>
            <p className="cgu-subtitle">6. Utilisation des cookies</p>
            <p className="cgu-txt">
              La plateforme utilise des cookies dans les cas suivants :<br />
              <br />• Nous pouvons collecter des informations sur votre
              ordinateur, y compris votre adresse IP, système d'exploitation et
              type de navigateur, pour l'administration du système et le bon
              fonctionnement de celui-ci. En aucun cas ces données collectées
              par les cookies ne seront transmises. <br />• Si des liens vers
              les réseaux sociaux sont présents sur la plateforme, et afin de
              rendre le « partage social » accessible, les cookies des réseaux
              sociaux sont présents : les cookies des réseaux sociaux varient et
              dépendent des paramètres de confidentialité propres à chaque
              réseau social. Les boutons « Partage social » ne déploieront des
              cookies que si vous êtes connecté au dit réseau social au moment
              de votre visite sur notre site web. <br />
              <br />
              Une rubrique dédiée à l’acceptation des cookies figure dans la
              politique de confidentialité, et doit faire l’objet d’acceptation
              à la première connexion. <br />
              <br />
              Les informations d’acceptation sont stockées et conservées dans la
              PFS, accessibles pour le gestionnaire.
              <br />
              <br />
              Comment désactiver les cookies ?<br />
              La plupart des navigateurs modernes vous permettent de contrôler
              vos paramètres de cookies pour tous les sites web que vous
              visitez. Vous pouvez désactiver complètement l’utilisation des
              cookies en modifiant les paramètres de votre navigateur.
              Cependant, vous risquez de limiter les fonctionnalités affichées
              sur notre site web.
            </p>
            <p className="cgu-subtitle">
              7. Changements à notre politique de confidentialité
            </p>
            <p className="cgu-txt">
              Tout changement que nous pourrions apporter à notre politique de
              confidentialité dans le futur sera publié sur cette page. En
              continuant à utiliser la plateforme, vous acceptez ces
              changements.
            </p>
            <p className="cgu-subtitle">8. Contact</p>
            <p className="cgu-txt">
              Les demandes et commentaires relatifs à cette politique de
              confidentialité sont les bienvenus et doivent être envoyés à :{' '}
            </p>
            <p className="cgu-txt-bold">
              ASCOTRONICS
              <br />
              DPO - Référent RGPD
              <br />
              357 Rue du champ de courses
              <br />
              38780 PONT-EVEQUE - FRANCE
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
};

CGUacg.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(CGUacg);
