import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_EVENT,
  EVENT_ERROR,
  CLEAR_EVENT,
  UPDATE_EVENT,
  GET_EVENTS
} from './types';

// Get all events log
export const getEventLog = () => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT });

  try {
    const res = await axios.get('/api/eventlog');

    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Piece by datetime
export const getEventLogByDateTime = (debut, fin) => async (dispatch) => {
  dispatch({ type: CLEAR_EVENT });

  try {
    const res = await axios.get(`/api/eventlog/datetime`, {
      params: {
        startDate: debut,
        endDate: fin
      }
    });

    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// clearEventLog
export const clearEventLog = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_EVENT });
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
