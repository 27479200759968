import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {
  getOptionsByPartialText,
  getAllOptions
} from '../../actions/optionfichegrue';
import ResultatOptionItem from './ResultatOptionItem';
import { useParams } from 'react-router-dom';

const ResultatsRechercheOption = ({
  getOptionsByPartialText,
  getAllOptions,
  optionfichegrue: { options, loadingopt }
}) => {
  const { partial } = useParams();

  useEffect(() => {
    if (partial) {
      if (partial === 'ALL_OPTIONS') {
        getAllOptions();
      } else {
        getOptionsByPartialText(partial.replace('|||', '/'));
      }
    }
  }, [getOptionsByPartialText, getAllOptions, partial]);

  const [nbOptions602, setnbOptions602] = useState(0);
  const [nbOptions603, setnbOptions603] = useState(0);
  const [nbOptionsDeleted, setnbOptionsDeleted] = useState(0);
  const [displayOption603, setdisplayOption603] = useState(true);
  const [displayOption602, setdisplayOption602] = useState(true);
  const [displayOptionDeleted, setdisplayOptionDeleted] = useState(true);
  const [sortedOptions, setSortedOptions] = useState([]);

  useEffect(() => {
    let compteur602 = 0;
    let compteur603 = 0;
    if (options.length > 0) {
      options.map((pc, index) =>
        pc.typemc600 === 602
          ? (compteur602 = compteur602 + 1)
          : (compteur603 = compteur603 + 1)
      );
      const count = options.filter((pc) => pc.deleted === true).length;
      setnbOptions602(compteur602);
      setnbOptions603(compteur603);
      setnbOptionsDeleted(count);
    }
    const cloneoptions = options.map((i) => ({ ...i }));
    cloneoptions.sort((a, b) => a.typemc600 - b.typemc600);
    setSortedOptions(cloneoptions);
  }, [options]);

  if (loadingopt) {
    return <Spinner />;
  } else if (options === null || options.length === 0) {
    return (
      <div style={{ marginTop: 100, marginBottom: 50 }}>
        <h3 className="text-center">
          Aucune option contenant {partial} n'a été trouvée
        </h3>
      </div>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Resultats recherche : {partial !== 'null' ? partial : ''}{' '}
            </h4>
            <div
              className="col text-center"
              style={{ marginBottom: 40, marginTop: 40 }}
            >
              <button
                type="button"
                class={`btn ${
                  displayOption602 === true ? 'btn-warning ' : 'btn-secondary '
                } text-center`}
                style={{ marginRight: 50 }}
                onClick={() => setdisplayOption602(!displayOption602)}
              >
                Options MC602{' '}
                <span class="badge badge-light">{nbOptions602}</span>
              </button>
              <button
                type="button"
                class={`btn ${
                  displayOption603 === true ? 'btn-success ' : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50 }}
                onClick={() => setdisplayOption603(!displayOption603)}
              >
                Options MC603{' '}
                <span class="badge badge-light">{nbOptions603}</span>
              </button>
              <button
                type="button"
                class={`btn ${
                  displayOptionDeleted === true
                    ? 'btn-danger '
                    : 'btn-secondary '
                } text-center`}
                style={{ marginLeft: 50 }}
                onClick={() => setdisplayOptionDeleted(!displayOptionDeleted)}
              >
                Options effacées{' '}
                <span class="badge badge-light">{nbOptionsDeleted}</span>
              </button>
            </div>
            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center" id="th1"></th>
                  <th className="text-center" id="th76"></th>

                  <th className="text-center " id="th5">
                    Nom
                  </th>
                  <th className="text-center" id="th6">
                    Reference X3
                  </th>
                  {/* <th className="text-center" id="origine">
                    date creation
                  </th> */}
                  <th className="text-center" id="liaison">
                    date modification
                  </th>
                  <th className="text-center" id="serie">
                    Commentaire
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedOptions.map((opti, index) => (
                  <ResultatOptionItem
                    displayOption602={displayOption602}
                    displayOption603={displayOption603}
                    displayOptionDeleted={displayOptionDeleted}
                    key={opti._id}
                    option={opti}
                    index={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

ResultatsRechercheOption.propTypes = {
  getOptionsByPartialText: PropTypes.func.isRequired,
  getAllOptions: PropTypes.func.isRequired,
  optionfichegrue: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  optionfichegrue: state.optionfichegrue
});

export default connect(mapStateToProps, {
  getOptionsByPartialText,
  getAllOptions
})(ResultatsRechercheOption);
