import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import UserItem from './UserItem';
import { getUsers } from '../../actions/user';
import { FaUser } from 'react-icons/fa';

const UserList = ({ auth, getUsers, user: { users, loading } }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null || users.length === 0 ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth &&
            auth.user &&
            (auth.user.rights === 'admin' ||
              auth.user.rights === 'admin_entity') ? (
              <Fragment>
                <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
                  Comptes utilisateur
                </h4>
                <div className="parent-flex" style={{ marginTop: '2rem' }}>
                  <p className="lead">
                    <FaUser
                      size={40}
                      color="grey"
                      style={{
                        marginRight: '1rem'
                      }}
                    />
                    Gestion comptes
                  </p>
                  <div className="child-left-auto">
                    <Link to="/create-user">
                      <button
                        className="btn btn-primary btn-lg"
                        style={{ marginRight: '0rem', marginBottom: '1rem' }}
                      >
                        Creation utilisateur
                      </button>
                    </Link>
                  </div>
                </div>
                <table
                  className="table text-nowrap table-striped table-light table-header-group table-container"
                  // border="1"
                >
                  <thead>
                    {' '}
                    <tr className="sticky">
                      <th className="text-center" id="th1" colSpan="1"></th>
                      <th className="text-center" id="th4">
                        Email
                      </th>
                      <th className="text-center hide-mobile" id="th2">
                        Description
                      </th>
                      <th className="text-center hide-mobile" id="th5">
                        Droits
                      </th>
                      <th className="text-center hide-mobile" id="th7">
                        Dern. connexion
                      </th>
                      <th className="text-center hide-mobile" id="th7">
                        Dern. modification
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      users.map((useur) => (
                        <UserItem key={useur._id} user={useur} />
                      ))
                    ) : (
                      <Fragment>aucun utilisateur trouvé </Fragment>
                    )}
                  </tbody>
                </table>{' '}
              </Fragment>
            ) : (
              <h4 className="large text-error">Accès non autorisé</h4>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserList.propTypes = {
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, { getUsers })(UserList);
