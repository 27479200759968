import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
// import ItemDynamicSelect from '../duplication/ItemDynamicSelect';
import { MdDelete } from 'react-icons/md';
import ModalImportPJ from '../duplication/ModalImportPJ';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import { Typeahead } from 'react-bootstrap-typeahead';

const ItemAjoutNew = ({
  duplication,
  nomenclature,
  eltNew,
  OnChangeQte,
  listePieces,
  selectIndex,
  onSelectionList,
  listeSequence,
  onSelectionSequence,
  onDeletePiece,
  OnChangePieceJointe,
  compCreateOption
}) => {
  const [singleSelections, setSingleSelections] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  function onSelection(array1) {
    setSingleSelections(array1);
    if (array1.length > 0 && array1[0].length > 0) {
      onSelectionList(array1[0].substring(1, array1[0].indexOf(']')));
      setIsValid(true);
      setIsInvalid(false);
    }
  }

  // if piece not selected from list, refx3 as to be null so that piece is not created in backend
  function inputChangeLibre(texte, index) {
    console.log('input change' + texte);
    onSelectionList('');
    setIsValid(false);
    setIsInvalid(true);
  }

  //Creation d'une liste de sequence pour le dynamicSelect
  function creationDynamicSelectSequence(list) {
    let items = [];
    items.push(
      <option selected disabled hidden value={0}>
        Seq ?
      </option>
    );
    for (let i = 0; i < list.length; i++) {
      items.push(
        <option key={i} value={list[i]}>
          {list[i]}
        </option>
      );
    }
    return items;
  }

  //Creation d'une liste de String à partir de la liste de pieces
  function creationListPiecesString(listPieces) {
    return listPieces.map((piece) => `[${piece.refx3}] - ${piece.nom}`);
  }

  return (
    <Fragment>
      {eltNew.visible === false ? (
        <Fragment></Fragment>
      ) : (
        <Fragment class="form-row align-items-center">
          <td>
            <select class="form-control" onClick={onSelectionSequence}>
              {creationDynamicSelectSequence(listeSequence)}
            </select>
          </td>
          {duplication === true ? (
            <Fragment>
              <td></td>
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
          <td class="text-center">
            <Typeahead
              class="text-center align-middle"
              id="nouveau elt"
              labelKey="elt"
              onChange={(tab) => onSelection(tab)}
              options={creationListPiecesString(listePieces)}
              onInputChange={(texte) => inputChangeLibre(texte)}
              placeholder={'Renseigner piece'}
              selected={singleSelections}
              isInvalid={isInvalid}
              isValid={isValid}
            />
          </td>
          {compCreateOption === true ? (
            <Fragment>
              <td> </td>
              <td> </td>
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
          <td>
            <input
              type="number"
              min="0"
              className="form-control"
              value={eltNew.qte}
              onChange={OnChangeQte}
              colspan="1"
            />
          </td>
          {/* <td class="text-center">
            <ModalImportPJ
              addPJ={OnChangePieceJointe}
              listPJ={eltNew.piecesjointes}
            />
          </td> */}
          {nomenclature !== true ? (
            <td class="text-center">
              <ListePiecesJointes
                OnChangePieceJointe={OnChangePieceJointe}
                tailleTexte="small"
                listPJ={eltNew.piecesjointes}
              />
            </td>
          ) : (
            <Fragment></Fragment>
          )}
          <td class="text-center">
            <button className="btn btn-warning" onClick={onDeletePiece}>
              <MdDelete />
            </button>
          </td>
        </Fragment>
      )}
    </Fragment>
  );
};

ItemAjoutNew.propTypes = {
  piece: PropTypes.object.isRequired
};

export default ItemAjoutNew;
