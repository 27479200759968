import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOptionById, createOption } from '../../actions/optionfichegrue';
import { getPiecesBySeq } from '../../actions/piece';
import { useParams, useNavigate } from 'react-router-dom';
import { MdSave } from 'react-icons/md';
import { MdCancel } from 'react-icons/md';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import ModalImportPJ from '../duplication/ModalImportPJ';
import Spinner from '../layout/Spinner';
import { setAlert } from '../../actions/alert';
import { IoIosAddCircleOutline } from 'react-icons/io';
import ItemAjoutNew from '../duplication/ItemAjoutNew';
import ItemListePieces from '../fichegrue/ItemListePieces';

const initialState = {
  nom: '',
  refx3: '',
  commentaire: '',
  typemc600: '603',
  piecesjointes: [],
  bom: []
};

const CreationOption = ({
  setAlert,
  getOptionById,
  getPiecesBySeq,
  createOption,
  optionfichegrue: { option, loadingopt },
  piece: { pieces, loadingpiece }
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formDataOption, setFormDataOption] = useState(initialState);
  const [bomNouvellesPieces, setBomNouvellesPieces] = useState([]);
  const [listSeq, setListSeq] = useState([]);
  const [listPiecesComplete, setListPiecesComplete] = useState([]);
  const [action, setaction] = useState(false);

  useEffect(() => {
    if (id !== '0') {
      getOptionById(id);
    }
    //Recuperation de la liste des pieces pour pouvoir afficher piece possible
    getPiecesBySeq('603');
  }, [getOptionById, getPiecesBySeq, id]);

  useEffect(() => {
    if (!loadingopt && option) {
      const data = { ...initialState };
      for (const key in data) {
        if (key in data) data[key] = option[key];
      }
      setFormDataOption(data);
    }
  }, [loadingopt, option]);

  useEffect(() => {
    console.log('update pieces');
    console.log(pieces);
    //Recuperation de la liste des sequences
    if (!loadingpiece && pieces.length > 0) {
      let items = [];
      for (let i = 1; i <= pieces.length; i++) {
        items.push(i * 10);
      }
      setListSeq(items);
    }
    //transformation list de list en list simple
    if (pieces.length > 0) {
      const clone = [];
      pieces.forEach((elt) => {
        elt.forEach((piece) => {
          clone.push(piece);
        });
      });
      setListPiecesComplete(clone);
    }
  }, [pieces, loadingpiece]);

  //TODO besoin de faire des modifications sur un element particulier de la BOM, peut etre prevoir une fonction speciale pour modif element BOM (qte, refx3, etc) selon index

  const onChangeDataOption = (e, name) => {
    setFormDataOption({
      ...formDataOption,
      [name]: e[0]
    });
  };

  const onChangeDataNewBOM = (prop, evt, index) => {
    const old = bomNouvellesPieces[index];
    let updated;
    if (prop === 'refx3') {
      updated = { ...old, piece: { refx3: evt } };
    } else {
      updated = { ...old, [prop]: parseInt(evt.target.value, 10) };
    }
    const clone = [...bomNouvellesPieces];
    clone[index] = updated;
    setBomNouvellesPieces(clone);
  };

  const onDeletePieceFormDataOption = (index) => {
    const old = formDataOption.bom[index];
    const updated = { ...old, piece: null };
    const clone = [...formDataOption.bom];
    clone[index] = updated;
    setFormDataOption({ ...formDataOption, bom: clone });
  };

  const onDeleteItemNewBOM = (index) => {
    const old = bomNouvellesPieces[index];
    const updated = { ...old, piece: null };
    const clone = [...bomNouvellesPieces];
    clone[index] = updated;
    setBomNouvellesPieces({ ...bomNouvellesPieces, bom: clone });
  };

  const AddNewElementBOM = () => {
    const clone = bomNouvellesPieces.map((i) => ({ ...i }));
    clone.push({ seq: 0, piece: null, qte: 1, visible: true });
    setBomNouvellesPieces(clone);
  };

  const SauvegarderOption = () => {
    if (formDataOption.refx3.length !== 9) {
      setAlert('La reference X3 doit faire 9 caractères', 'danger');
    }
    if (formDataOption.nom.length === 0) {
      setAlert("Veuillez entrer un nom pour l'option svp", 'danger');
    }
    if (formDataOption.bom.length === 0 && bomNouvellesPieces.length === 0) {
      setAlert(
        "Veuillez ajouter des pièces à la bom de l'option svp",
        'danger'
      );
    }

    if (
      formDataOption.refx3.length === 9 &&
      formDataOption.nom.length !== 0 &&
      (formDataOption.bom.length !== 0 || bomNouvellesPieces.length !== 0)
    ) {
      if (
        window.confirm('Etes vous sur de vouloir sauvegarder cette option ?')
      ) {
        setaction(true);

        createOption(
          id !== '0' ? id : null,
          {
            ...formDataOption,
            bom: formDataOption.bom.concat(
              bomNouvellesPieces.filter(
                (elt) =>
                  elt.piece !== null && elt.seq !== 0 && elt.visible !== false
              )
            )
          },
          navigate
        );
      }
    }
  };

  useEffect(() => {
    console.log('update formDataOption');
    console.log(formDataOption);
  }, [formDataOption]);

  console.log(bomNouvellesPieces.length);

  if ((loadingopt && id !== '0') || action) {
    return <Spinner />;
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              {id !== '0' ? 'Modification option' : 'Creation option'}
            </h4>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Nom:
                </label>
                <div class="col-10">
                  <input
                    type="text"
                    className="form-control "
                    value={formDataOption.nom}
                    onChange={(e) =>
                      onChangeDataOption([e.target.value], 'nom')
                    }
                    placeholder="Nom"
                  />
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Reference X3:
                </label>
                <div class="col-10">
                  <input
                    type="text"
                    className="form-control "
                    value={formDataOption.refx3}
                    onChange={(e) =>
                      onChangeDataOption([e.target.value], 'refx3')
                    }
                    placeholder="Reference X3"
                  />
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Type:
                </label>
                <div class="col-10">
                  <select
                    name="status"
                    value={formDataOption.typemc600}
                    className="form-control "
                    onChange={(e) =>
                      onChangeDataOption([e.target.value], 'typemc600')
                    }
                  >
                    <option value="603">MC603</option>
                    <option value="602">MC602</option>
                  </select>
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Commentaire:
                </label>
                <div class="col-10">
                  <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Renseigner vos commentaires ici"
                    type="text"
                    name="commentaire"
                    value={formDataOption.commentaire}
                    onChange={(e) =>
                      onChangeDataOption([e.target.value], 'commentaire')
                    }
                  ></textarea>
                </div>
              </form>
              {/* </form> */}
            </div>
          </div>
          {/* <Fragment> */}
          {/* <div className="row"> */}
          <div className="card">
            <div className="card-header" style={headStyle}>
              BOM :
            </div>
            <div>
              <table
                className="table table-striped table-light table-header-group"
                style={{ marginTop: 5 }}
                border="1"
              >
                <thead>
                  <tr className="title-td">
                    <td id="th1" width="100" class="text-center">
                      Seq
                    </td>
                    <td className="text-center" id="th3">
                      Nom pièce
                    </td>
                    <td className="text-center" id="th3" width="100">
                      Commentaire
                    </td>
                    <td className="text-center" id="th4">
                      réfèrence X3
                    </td>
                    <td className="text-center" id="th5" width="80">
                      Qté
                    </td>
                    <td className="text-center" id="th6">
                      P.J
                    </td>
                    <td className="text-center" id="th6">
                      Vignette
                    </td>
                    <td className="text-center" id="th7"></td>
                  </tr>
                </thead>
                <tr>
                  <td id="th1" colspan="12">
                    <button
                      className="btn btn-primary"
                      onClick={AddNewElementBOM}
                    >
                      Ajouter element <IoIosAddCircleOutline />
                    </button>
                  </td>
                </tr>

                <tbody>
                  {bomNouvellesPieces.length > 0 ? (
                    bomNouvellesPieces.map((elt, index) => (
                      <Fragment>
                        <tr>
                          <ItemAjoutNew
                            duplication={false}
                            listeSequence={listSeq}
                            eltNew={elt}
                            OnChangeQte={(e) =>
                              onChangeDataNewBOM('qte', e, index)
                            }
                            listePieces={listPiecesComplete}
                            selectIndex={0}
                            onSelectionList={(e) =>
                              onChangeDataNewBOM('refx3', e, index)
                            }
                            onSelectionSequence={(e) =>
                              onChangeDataNewBOM('seq', e, index)
                            }
                            onDeletePiece={(e) => onDeleteItemNewBOM(index)}
                            OnChangePieceJointe={(e) =>
                              onChangeDataNewBOM('piecesjointes', e, index)
                            }
                            compCreateOption={true}
                          />
                        </tr>
                      </Fragment>
                    ))
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {formDataOption.bom.map((elt, index) =>
                    elt.piece === null ? (
                      <div></div>
                    ) : (
                      <tr>
                        <ItemListePieces
                          eltBom={elt}
                          onDelete={(e) => onDeletePieceFormDataOption(index)}
                        />
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
          {/* // </Fragment> */}
          {/* <Fragment> */}
          {/* <div className="row" style={{ marginLeft: 0 }}> */}
          <div className="card">
            <div className="card-header" style={headStyle}>
              Pièces jointes :
            </div>
            <ListePiecesJointes
              OnChangePieceJointe={(list) =>
                onChangeDataOption([list], 'piecesjointes')
              }
              tailleTexte="normal"
              listPJ={formDataOption.piecesjointes}
            />
          </div>
          <div style={{ margin: 10 }}>
            <ModalImportPJ
              addPJ={(list) => onChangeDataOption([list], 'piecesjointes')}
              listPJ={formDataOption.piecesjointes}
            />
          </div>
          {/* </div> */}
          {/* // </Fragment> */}
          <div style={{ marginTop: 50 }} className="row ">
            <div className="col-12 text-center ">
              <button
                className="btn btn-success btn-lg"
                onClick={SauvegarderOption}
              >
                Sauvegarder option <MdSave />
              </button>

              <button
                className="btn btn-secondary btn-lg"
                onClick={() => navigate(-1)}
              >
                Annuler <MdCancel />
              </button>
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

CreationOption.propTypes = {
  auth: PropTypes.object.isRequired,
  optionfichegrue: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  createOption: PropTypes.func.isRequired,
  getPiecesBySeq: PropTypes.func.isRequired,
  getOptionById: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  optionfichegrue: state.optionfichegrue,
  piece: state.piece,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getOptionById,
  createOption,
  getPiecesBySeq,
  setAlert
})(CreationOption);

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};

const labelStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
