import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import { FaAngleDown } from 'react-icons/fa';
import Moment from 'react-moment';
import moment from 'moment';

const DetailFicheGrueEntete = ({
  fichegrue,
  fichegrue: {
    marque,
    modele,
    serie,
    type1,
    type2,
    origine,
    piecesjointes,
    commentaire,
    archive,
    liaison,
    serialnumber,
    anneeprod,
    infovue,
    typemc600,
    index,
    nbs,
    module,
    date
  }
}) => {
  return (
    <Card>
      <div style={{ marginBottom: 1 }}>
        <div style={headStyle}>Détails fiche</div>

        <div style={{ marginLeft: 20, marginTop: 20 }}>
          {marque !== undefined ? (
            <p>
              <span className="text-center ">Marque :</span>{' '}
              <span className="text-center text-primary">{marque}</span>
            </p>
          ) : (
            <p></p>
          )}
          {modele !== undefined ? (
            <p>
              <span className="text-center ">Modele :</span>{' '}
              <span className="text-center text-primary">{modele}</span>
            </p>
          ) : (
            <p></p>
          )}
          {serie !== undefined ? (
            <p>
              <span className="text-center ">Serie :</span>{' '}
              <span className="text-center text-primary">{serie}</span>
            </p>
          ) : (
            <p></p>
          )}
          {type1 !== undefined ? (
            <p>
              <span className="text-center ">Type :</span>{' '}
              <span className="text-center text-primary">{type1}</span>
            </p>
          ) : (
            <p></p>
          )}
          {origine !== undefined ? (
            <p>
              <span className="text-center ">Origine :</span>{' '}
              <span className="text-center text-primary">{origine}</span>
            </p>
          ) : (
            <p></p>
          )}
          {liaison !== undefined ? (
            <p>
              <span className="text-center ">Liaison :</span>{' '}
              <span className="text-center text-primary">{liaison}</span>
            </p>
          ) : (
            <p></p>
          )}
          {serialnumber !== undefined ? (
            <p>
              <span className="text-center ">Serial number :</span>{' '}
              <span className="text-center text-primary">{serialnumber}</span>
            </p>
          ) : (
            <p></p>
          )}
          {anneeprod !== undefined ? (
            <p>
              <span className="text-center ">Année production :</span>{' '}
              <span className="text-center text-primary">{anneeprod}</span>
            </p>
          ) : (
            <p></p>
          )}
          {infovue !== undefined ? (
            <p>
              <span className="text-center ">Info vue :</span>{' '}
              <span className="text-center text-primary">{infovue}</span>
            </p>
          ) : (
            <p></p>
          )}
          {index !== undefined ? (
            <p>
              <span className="text-center ">Index :</span>{' '}
              <span className="text-center text-primary">{index}</span>
            </p>
          ) : (
            <p></p>
          )}
          {nbs !== undefined ? (
            <p>
              <span className="text-center ">Nb dents couronne :</span>{' '}
              <span className="text-center text-primary">{nbs}</span>
            </p>
          ) : (
            <p></p>
          )}
          {module !== undefined ? (
            <p>
              <span className="text-center ">Module :</span>{' '}
              <span className="text-center text-primary">{module}</span>
            </p>
          ) : (
            <p></p>
          )}
          {date !== undefined ? (
            <p>
              <span className="text-center ">Date :</span>{' '}
              <Moment
                className="text-center text-primary"
                format="DD/MM/YYYY HH:mm"
              >
                {moment.utc(date).local()}
              </Moment>
            </p>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </Card>
  );
};

DetailFicheGrueEntete.propTypes = {
  fichegrue: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default DetailFicheGrueEntete;

const SSStyle = {
  fontSize: '20px',
  background: '#FFFFFF'
  // color: '#FFFFFF',
  // fontWeight: 'bold',
  // textAlign: 'center'
};

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
