import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';

const ItemNomenclatureBase = ({
  fichegrue: { _id: id, serie, nomenclature_com, date, typemc600 },
  displayFiche602,
  displayFiche603
}) => {
  return (
    <Fragment class="form-row align-items-center">
      {(displayFiche603 === true && typemc600 === 603) ||
      (displayFiche602 === true && typemc600 === 602) ? (
        <tr>
          <td width="70">
            {typemc600 === 603 ? (
              <h4>
                <span class="badge badge-pill badge-success p-2 mb-2 ">
                  603
                </span>
              </h4>
            ) : (
              <h4>
                <span class="badge badge-pill badge-warning p-2 mb-2 ">
                  602
                </span>
              </h4>
            )}
          </td>
          <td id="th7" width="70">
            <Link to={`/nomenclaturebase/${typemc600}/${id}`}>
              <button className="btn btn-ascorel btn-sm">
                Detail <MdInfoOutline color="white" />
              </button>
            </Link>
          </td>
          <td class="text-center align-middle" id="th1" width="100">
            <Moment format="DD/MM/YYYY">{moment.utc(date)}</Moment>
          </td>
          <td class="text-center align-middle" id="marque">
            {nomenclature_com}
          </td>
          <td class="text-center align-middle" id="modele">
            {serie}
          </td>
        </tr>
      ) : (
        <tr></tr>
      )}
    </Fragment>
  );
};

ItemNomenclatureBase.propTypes = {
  fichegrue: PropTypes.object.isRequired,
  deleteFiche: PropTypes.func.isRequired
};

export default ItemNomenclatureBase;
