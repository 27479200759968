import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { MdPlaylistAdd } from 'react-icons/md';
import { clearOption } from '../../actions/optionfichegrue';

const RechercheOption = ({ clearOption, auth }) => {
  useEffect(() => {
    clearOption();
  }, [clearOption]);

  const [textRecherche, setTextRecherche] = useState('');

  const navigate = useNavigate();

  const routeChange = () => {
    let path =
      textRecherche !== ''
        ? `/resultatsoption/${textRecherche.replace('/', '|||')}/`
        : `/resultatsoption/ALL_OPTIONS/`;
    navigate(path);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        console.log('Enter key was pressed. Run your function.');
        event.preventDefault();
        routeChange();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [textRecherche]);

  return (
    <section className="container">
      <div>
        <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
          Recherche Option
        </h4>
        <div class="row text-center" style={{ marginTop: 30 }}>
          <div class="row  col-xs-6" style={{ margin: 10 }}>
            <div style={{ margin: 10 }}>
              <input
                type="text"
                className="input form-control center-block"
                value={textRecherche}
                onChange={(e) => setTextRecherche(e.target.value)}
                placeholder="Entrez texte recherché"
              />
            </div>
          </div>
          <div className=" text-center col-xs-6" style={{ margin: 10 }}>
            <div className="row">
              <div className="col text-center">
                <button
                  style={btnStyle}
                  className="btn btn-primary btn-lg center-block"
                  onClick={routeChange}
                >
                  Rechercher option <FaSearch />
                </button>

                <Link to="/creationoption/0" className="text-navbar">
                  <button
                    style={btnStyle}
                    className="btn btn-primary btn-lg center-block"
                  >
                    Creer option <MdPlaylistAdd />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

RechercheOption.propTypes = {
  clearOption: PropTypes.func.isRequired,
  optionfichegrue: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  optionfichegrue: state.optionfichegrue
});

export default connect(mapStateToProps, {
  clearOption
})(RechercheOption);

const btnStyle = {
  margin: '8px',
  padding: '8px'
};
