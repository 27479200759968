import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_OPTION,
  OPTION_ERROR,
  CLEAR_OPTION,
  UPDATE_OPTION,
  GET_OPTIONS
} from './types';

// Get all options
export const getAllOptions = () => async (dispatch) => {
  dispatch({ type: CLEAR_OPTION });

  try {
    const res = await axios.get('/api/optionfichegrue');

    dispatch({
      type: GET_OPTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get options by MC600 type
export const getOptions = (MC600) => async (dispatch) => {
  dispatch({ type: CLEAR_OPTION });

  try {
    const res = await axios.get(`/api/optionfichegrue/${MC600}`);

    dispatch({
      type: GET_OPTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Option by ID
export const getOptionById = (optionId) => async (dispatch) => {
  dispatch({ type: CLEAR_OPTION });

  try {
    const res = await axios.get(`/api/optionfichegrue/id/${optionId}`);

    dispatch({
      type: GET_OPTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get optionfichegrue by parameter refx3
export const getOptionByParameter = (refx3) => async (dispatch) => {
  dispatch({ type: CLEAR_OPTION });

  try {
    const res = await axios.get(`/api/optionfichegrue/refx3/${refx3}`);

    dispatch({
      type: GET_OPTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all Options by partial text
export const getOptionsByPartialText = (text) => async (dispatch) => {
  dispatch({ type: CLEAR_OPTION });

  try {
    const res = await axios.get(`/api/optionfichegrue/partial/${text}`);

    dispatch({
      type: GET_OPTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update Fichegrue
export const createOption =
  (id, formData, navigate = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(
        id ? `/api/optionfichegrue/${id}` : '/api/optionfichegrue',
        formData,
        config
      );
      dispatch({
        type: UPDATE_OPTION,
        payload: res.data
      });

      dispatch(setAlert(id ? 'Option Updated' : 'Option Created', 'success'));

      navigate(`/option/${res.data._id}`);
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: OPTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

//  delete Option
export const deleteOption = (optionId) => async (dispatch) => {
  //TODO prevoir traduction anglais
  if (
    window.confirm('Etes vous sur ? Cette opération ne peut pas être annulée !')
  ) {
    try {
      await axios.delete(`/api/optionfichegrue/${optionId}`);

      dispatch({ type: CLEAR_OPTION });

      dispatch(setAlert('Option effacée'));
    } catch (err) {
      dispatch({
        type: OPTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};

// clearOption
export const clearOption = () => async (dispatch) => {
  try {
    console.log(CLEAR_OPTION);
    dispatch({ type: CLEAR_OPTION });
  } catch (err) {
    dispatch({
      type: OPTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
