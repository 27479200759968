import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getUserById, deleteUser, createUser } from '../../actions/user';
import Moment from 'react-moment';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import { GiCoffin } from 'react-icons/gi';
import { GiRaiseZombie } from 'react-icons/gi';
import { AiOutlineEdit } from 'react-icons/ai';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const UserDetail = ({
  auth_id,
  getUserById,
  deleteUser,
  createUser,
  user: { user, loading },
  auth
}) => {
  const DeleteCompte = () => {
    // if (window.confirm('Etes vous sur de vouloir supprimer ce compte ?')) {
    setaction(true);
    deleteUser(auth_id ? auth_id : id);
    setTimeout(() => {
      navigate('/users');
    }, 1000);
    // }
  };

  const DisableCompte = (etat, txt) => {
    if (window.confirm(txt)) {
      setaction(true);
      createUser(auth_id ? auth_id : id, { enabled: etat });
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  const navigate = useNavigate();
  const [action, setaction] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getUserById(auth_id ? auth_id : id);
  }, [getUserById, id, auth_id]);

  return (
    <section className="container">
      <Fragment>
        {user === null || loading || action ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="row">
              {auth.user.rights === 'user' ? (
                <div className="col-auto">
                  <Link to="/dashboard" className="btn btn-dark">
                    <AiOutlineArrowLeft /> Retour page principale
                  </Link>
                </div>
              ) : (
                <div className="col-auto">
                  <Link
                    to="/users"
                    className="btn btn-dark"
                    style={{
                      marginBottom: '1rem'
                    }}
                  >
                    <AiOutlineArrowLeft /> Retour Liste comptes
                  </Link>
                </div>
              )}
              <div className="col-auto">
                <Link
                  to={`/edit-user/${auth_id ? auth_id : id}`}
                  className="btn btn-dark"
                  style={{
                    marginBottom: '1rem'
                  }}
                >
                  Modifier Utilisateur
                  <AiOutlineEdit />
                </Link>
              </div>
              {auth.isAuthenticated &&
                auth.loading === false &&
                (auth.user.rights === 'admin' ||
                  auth.user.rights === 'admin_entity') && (
                  <Fragment>
                    {user.enabled ? (
                      <div className="col-auto">
                        <button
                          className="btn btn-warning btn-lg"
                          style={{
                            marginBottom: '1rem'
                          }}
                          onClick={() =>
                            DisableCompte(
                              false,
                              'Etes vous sur de vouloir desactiver ce compte ?'
                            )
                          }
                        >
                          Desactiver compte <GiCoffin />
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <button
                          className="btn btn-warning btn-lg"
                          style={{
                            marginBottom: '1rem'
                          }}
                          onClick={() =>
                            DisableCompte(
                              true,
                              'Etes vous sur de vouloir activer ce compte ?'
                            )
                          }
                        >
                          Reactiver compte
                          <GiRaiseZombie />
                        </button>
                      </div>
                    )}
                  </Fragment>
                )}
              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user.rights === 'admin' && (
                  <div className="col-auto">
                    <button
                      className="btn btn-danger btn-lg"
                      style={{
                        marginBottom: '1rem'
                      }}
                      onClick={() => DeleteCompte()}
                    >
                      Supprimer compte
                    </button>
                  </div>
                )}
            </div>
            {user.enabled ? (
              <div></div>
            ) : (
              <h4 className="bg-warning my-2 p-2 text-center">
                Compte DESACTIVE
              </h4>
            )}
            <div className="profile-grid my-1">
              <div
                className={
                  user.enabled
                    ? 'profile-top bg-ascorel p-2'
                    : 'profile-top-disabled bg-ascorel p-2'
                }
              >
                {/* <img className="round-img my-4" src={avatar} alt="" /> */}
                <p className="lead">
                  {user.email && <span> email : {user.email}</span>}
                </p>
                <p className="lead">
                  {user.name && <span> Description : {user.name}</span>}
                </p>
                <p className="lead">
                  {user.entity && <span> Entité : {user.entity}</span>}
                </p>
                <p className="lead">
                  {user.rights && <span> Droits : {user.rights}</span>}
                </p>
                <p className="lead">
                  {user.lastSeen ? (
                    <span>
                      Dernière connexion :
                      <Moment format=" DD/MM/YYYY hh:mm:ss">
                        {moment(user.lastSeen).local()}
                      </Moment>
                    </span>
                  ) : (
                    <span> Dernière connexion: ---</span>
                  )}
                </p>
                <p className="lead">
                  {user.date && (
                    <span>
                      Modifié :
                      <Moment format=" DD/MM/YYYY hh:mm:ss">
                        {moment(user.date).local()}
                      </Moment>
                    </span>
                  )}
                </p>
              </div>
            </div>
            {auth.user._id === id ? (
              <label
                className="col-auto"
                style={{
                  fontSize: '1rem'
                }}
              >
                <Link to={'/cgu'}>
                  Consulter les conditions générales d'utilisation
                </Link>
              </label>
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserDetail.propTypes = {
  getUserById: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getUserById,
  deleteUser,
  createUser
})(UserDetail);
