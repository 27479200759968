import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
const Buffer = require('buffer/').Buffer;

const ResultatPieceItem = ({
  index,
  piece: {
    _id: id,
    nom,
    refx3,
    commentaire,
    seqpossible,
    datecreation,
    datemodification,
    typemc600,
    deleted,
    piecesjointes,
    vignette
  },
  displayPiece602,
  displayPiece603,
  displayPieceDeleted
}) => {
  var completionPJ =
    (piecesjointes.filter((i) => i.piecejointe.type === 'img').length > 0
      ? 33
      : 0) +
    (piecesjointes.filter((i) => i.piecejointe.type === 'txt').length > 0
      ? 33
      : 0) +
    (piecesjointes.filter((i) => i.piecejointe.type === 'pdf').length > 0
      ? 33
      : 0);
  // var completionPJ = 15;
  completionPJ = completionPJ === 0 ? 10 : completionPJ;
  completionPJ = completionPJ === 99 ? 100 : completionPJ;

  const variantPJ =
    completionPJ <= 33 ? 'danger' : completionPJ <= 66 ? 'warning' : 'success';

  let base64ImageString;
  if (vignette && vignette.img && vignette.img.data) {
    base64ImageString = Buffer.from(vignette.img.data.data, 'binary').toString(
      'base64'
    );
  }
  return (
    <Fragment className="form-row align-items-center">
      {((displayPiece603 === true && typemc600 === 603) ||
        (displayPiece602 === true && typemc600 === 602)) &&
      !(displayPieceDeleted === false && deleted === true) ? (
        <tr>
          <td id="th0">
            {typemc600 === 603 ? (
              <h4>
                <span className="text-center align-middle badge badge-pill badge-success">
                  603
                </span>
              </h4>
            ) : (
              <h4>
                <span className="text-center align-middle badge badge-pill badge-warning">
                  602
                </span>
              </h4>
            )}
          </td>
          <td id="th7">
            <Link to={`/piece/${id}`}>
              <button className="btn btn-ascorel btn-sm">
                Detail <MdInfoOutline color="white" />
              </button>
            </Link>
          </td>

          <td
            className="text-center align-middle "
            id="marque"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {nom}
          </td>
          <td
            className="text-center align-middle"
            id="modele"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {refx3}
          </td>
          <td
            className="text-center align-middle"
            id="serie"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {vignette && vignette.img && vignette.img.contentType ? (
              <div
                style={{ marginTop: 10 }}
                className="image-preview text-center align-middle"
              >
                <img
                  className="vignette-list"
                  src={`data:${vignette.img.contentType};base64,${base64ImageString}`}
                  alt="Preview"
                />
              </div>
            ) : (
              <div> - </div>
            )}
          </td>
          <td
            className="text-center align-middle"
            id="serie"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {seqpossible.join(' / ')}
          </td>
          {datemodification !== undefined ? (
            <td
              className="text-center align-middle"
              id="th1"
              width="100"
              style={{
                textDecorationLine: deleted ? 'line-through' : ''
              }}
            >
              <Moment format="DD/MM/YYYY">
                {moment.utc(datemodification)}
              </Moment>
            </td>
          ) : (
            <td></td>
          )}
          {/* <td
            className="text-center align-middle"
            id="commentaire"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {commentaire}
          </td> */}
          <td
            className="text-center align-middle"
            id="pj"
            width="200"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            <ProgressBar
              now={completionPJ}
              label={`${completionPJ}%`}
              variant={variantPJ}
            />
          </td>
        </tr>
      ) : (
        <tr></tr>
      )}
    </Fragment>
  );
};

ResultatPieceItem.propTypes = {
  piece: PropTypes.object.isRequired
  // deleteFiche: PropTypes.func.isRequired
};

export default ResultatPieceItem;
