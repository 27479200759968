import {
  GET_FICHEGRUE,
  FICHEGRUE_ERROR,
  CLEAR_FICHEGRUE,
  UPDATE_FICHEGRUE,
  GET_FICHESGRUES,
  GET_DISTINCT_MARQUE,
  GET_DISTINCT_MODELE,
  GET_DISTINCT_TYPE,
  GET_DISTINCT_LIAISON,
  GET_DISTINCT_CUSTOM,
  GET_DISTINCT_REFRESH_All
} from '../actions/types';

const initialState = {
  fichegrue: null,
  fichesgrues: [],
  distinctmarque: [],
  distinctmodele: [],
  distincttype: [],
  distinctliaison: [],
  distinctcustom: [],
  distincts: [],
  loading: true,
  update: false,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FICHEGRUE:
      return {
        ...state,
        fichegrue: payload,
        loading: false
      };
    case UPDATE_FICHEGRUE:
      return {
        ...state,
        fichegrue: payload,
        loading: false,
        update: true
      };
    case GET_DISTINCT_MARQUE:
      return {
        ...state,
        distinctmarque: payload
        // loading: false
      };
    case GET_DISTINCT_MODELE:
      return {
        ...state,
        distinctmodele: payload
        // loading: false
      };
    case GET_DISTINCT_TYPE:
      return {
        ...state,
        distincttype: payload
        // loading: false
      };
    case GET_DISTINCT_LIAISON:
      return {
        ...state,
        distinctliaison: payload
        // loading: false
      };
    case GET_DISTINCT_CUSTOM:
      return {
        ...state,
        distinctcustom: payload
        // loading: false
      };
    case GET_DISTINCT_REFRESH_All:
      return {
        ...state,
        distincts: payload,
        loading: false
      };
    case GET_FICHESGRUES:
      return {
        ...state,
        fichesgrues: payload,
        loading: false
      };
    case FICHEGRUE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        fichegrue: null
      };
    case CLEAR_FICHEGRUE:
      return {
        ...state,
        fichegrue: null,
        fichesgrues: [],
        distinctmarque: [],
        distinctmodele: [],
        distincttype: [],
        distinctliaison: [],
        loading: true,
        update: false
      };

    default:
      return state;
  }
}
