import {
  GET_PIECE,
  PIECE_ERROR,
  CLEAR_PIECE,
  UPDATE_PIECE,
  GET_PIECES
} from '../actions/types';

const initialState = {
  piece: null,
  pieces: [],
  loadingpiece: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PIECE:
    case UPDATE_PIECE:
      return {
        ...state,
        piece: payload,
        loadingpiece: false
      };
    case GET_PIECES:
      return {
        ...state,
        pieces: payload,
        loadingpiece: false
      };
    case PIECE_ERROR:
      return {
        ...state,
        error: payload,
        loadingpiece: false,
        piece: null
      };
    case CLEAR_PIECE:
      return {
        ...state,
        piece: null,
        pieces: [],
        loadingpiece: true
      };

    default:
      return state;
  }
}
