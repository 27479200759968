import React, { useState, useRef } from 'react';

import { MdAttachFile } from 'react-icons/md';
import { RiDragDropLine } from 'react-icons/ri';
import { AiOutlineDownload } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropzone from 'react-dropzone';
import axios from 'axios';

const ModalImportPJ = ({ addPJ, listPJ, importVignette }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [file, setFile] = useState(null); // state for storing actual image
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
  const [errorMsg, setErrorMsg] = useState('');
  const [importsuccess, setImportsuccess] = useState(false);

  const dropRef = useRef(); // React ref for managing the hover state of droppable area

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
    dropRef.current.style.border = '2px dashed #e9ebeb';
  };

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  const retourApresImport = () => {
    setModalShow(false);
    setImportsuccess(false);
    setFile(null);
    setPreviewSrc('');
    setIsPreviewAvailable(false);
    setErrorMsg('');
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('nom', file.name);
        if (file.name.includes('.pdf')) {
          formData.append('type', 'pdf');
        } else if (
          file.name.includes('.png') ||
          file.name.includes('.bmp') ||
          file.name.includes('.jpeg') ||
          file.name.includes('.jpg')
        ) {
          formData.append('type', 'img');
        } else if (
          file.name.includes('.doc') ||
          file.name.includes('.docx') ||
          file.name.includes('.odf') ||
          file.name.includes('.odf') ||
          file.name.includes('.txt')
        ) {
          formData.append('type', 'txt');
        }
        console.log(file);
        const res = await axios.post('/api/piecejointe/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(res.status);
        if (res.status === 200) {
          setImportsuccess(true);
          if (importVignette) {
            addPJ({ file, data: [{ nom: res.data.nom, _id: res.data.id }] });
          } else if (listPJ === undefined) {
            addPJ([{ piecejointe: { nom: res.data.nom, _id: res.data.id } }]);
          } else {
            addPJ([
              ...listPJ,
              { piecejointe: { nom: res.data.nom, _id: res.data.id } }
            ]);
          }

          setTimeout(() => {
            retourApresImport();
          }, 2000);
        }
      } else {
        setErrorMsg('Veuillez selectionner un fichier à importer');
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          setErrorMsg(error.response.data);
        } else if (error.response.status === 500) {
          setErrorMsg('La taille du fichier depasse la limite autorisée (2Mo)');
        }
      }
    }
  };

  function VerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {importVignette !== true ? (
              <Modal.Title id="contained-modal-title-vcenter">
                Ajout Pièce jointe
              </Modal.Title>
            ) : (
              <Modal.Title id="contained-modal-title-vcenter">
                Ajout vignette
              </Modal.Title>
            )}
          </Modal.Title>
        </Modal.Header>
        {importsuccess === false ? (
          <Modal.Body>
            {importVignette !== true ? (
              <React.Fragment>
                <div className="text-center text-primary">
                  <p>
                    <span className="text-center ">
                      <b>Fichier texte (.doc/.docx/.txt/.odf):</b>
                    </span>{' '}
                    {listPJ.filter(
                      (i) => i.piecejointe && i.piecejointe.type === 'txt'
                    ).length > 0 ? (
                      <span style={{ color: 'green' }}>Présent</span>
                    ) : (
                      <span style={{ color: 'red' }}>Manquant</span>
                    )}
                  </p>
                </div>

                <div className="text-center text-primary">
                  <p>
                    <span className="text-center ">
                      <b>Fichier pdf (.pdf) :</b>
                    </span>{' '}
                    {listPJ.filter(
                      (i) => i.piecejointe && i.piecejointe.type === 'pdf'
                    ).length > 0 ? (
                      <span style={{ color: 'green' }}>Présent</span>
                    ) : (
                      <span style={{ color: 'red' }}>Manquant</span>
                    )}
                  </p>
                </div>
                <div className="text-center text-primary">
                  <p>
                    <span className="text-center ">
                      <b>Fichier image (.jpeg/.jpg/.png/.bmp):</b>
                    </span>{' '}
                    {listPJ.filter(
                      (i) => i.piecejointe && i.piecejointe.type === 'img'
                    ).length > 0 ? (
                      <span style={{ color: 'green' }}>Présent</span>
                    ) : (
                      <span style={{ color: 'red' }}>Manquant</span>
                    )}
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <div></div>
            )}

            <React.Fragment>
              <Form className="search-form" onSubmit={handleOnSubmit}>
                {errorMsg && (
                  <p className="errorMsg text-center text-danger">{errorMsg}</p>
                )}

                <div className="upload-section">
                  <Dropzone
                    onDrop={onDrop}
                    onDragEnter={() => updateBorder('over')}
                    onDragLeave={() => updateBorder('leave')}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({ className: 'drop-zone' })}
                        ref={dropRef}
                      >
                        <input {...getInputProps()} />
                        <p className="text-center">
                          Deposer votre fichier ici ou cliquer pour explorer
                        </p>
                        <RiDragDropLine size={64} />
                        {file && (
                          <div>
                            <strong>Fichier selectionné:</strong> {file.name}{' '}
                            {'('}
                            {Math.round(file.size / 1024)}
                            {' Ko)'}
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                  {previewSrc ? (
                    isPreviewAvailable ? (
                      <div className="image-preview">
                        <img
                          className="preview-image"
                          src={previewSrc}
                          alt="Preview"
                        />
                      </div>
                    ) : (
                      <div className="preview-message d-flex align-items-center">
                        <p className="text-center">
                          Pas de visualisation pour ce fichier
                        </p>
                      </div>
                    )
                  ) : (
                    <div className="preview-message d-flex align-items-center">
                      <p className="text-center">
                        Visualisation disponible après selection du fichier
                      </p>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: 10 }} className="float-right">
                  <Button onClick={props.onHide}>Annuler</Button>

                  <Button variant="primary" type="submit">
                    Importer
                  </Button>
                </div>
              </Form>
            </React.Fragment>
          </Modal.Body>
        ) : (
          <Modal.Body>
            {/* <React.Fragment> */}
            <div className="text-center ">
              <p className="text-success">
                le fichier a été importé avec succès !
              </p>
              <AiOutlineDownload className="text-success" size={64} />
              <div style={{ marginTop: 20 }}>
                <Button onClick={retourApresImport}>Retour</Button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }

  return (
    <>
      <Button onClick={() => setModalShow(true)}>
        {importVignette !== true ? (
          <MdAttachFile />
        ) : (
          <div>
            <p className="text-center">Cliquer pour ajouter une vignette</p>
            <RiDragDropLine size={32} />
          </div>
        )}
      </Button>

      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

// ModalImportPJ.propTypes = {
//   piece: PropTypes.object.isRequired
// };

export default ModalImportPJ;
