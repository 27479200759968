import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

import { MdDelete } from 'react-icons/md';
import { TiDeleteOutline } from 'react-icons/ti';

const DeletePieceJointe = ({ idAEffacer, addPJ, listPJ }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);

  const retourApresDelete = () => {
    setModalShow(false);
    setDeleteSuccess(false);
    console.log('retourApresDelete');
  };

  const deletePJ = async () => {
    try {
      const res = await axios.delete(`/api/piecejointe/${idAEffacer}`, {});
      if (res.status === 200) {
        setDeleteSuccess(true);
        console.log('setDeleteSuccess ' + deleteSuccess);
        if (listPJ !== undefined) {
          const myArray = listPJ.filter(function (pj) {
            return pj.piecejointe._id !== idAEffacer;
          });
          console.log(myArray);

          setTimeout(() => {
            addPJ(myArray);
            retourApresDelete();
          }, 2000);

          // addPJ(myArray);
        }
      }
    } catch (error) {
      // if (error.response && error.response.status === 400) {
      //   // setErrorMsg('Error while downloading file. Try again later');
      // }
    }
  };

  function VerticallyCenteredModal(props) {
    console.log('VerticallyCenteredModal deleteSuccess = ' + deleteSuccess);
    console.log('VerticallyCenteredModal modalShow = ' + modalShow);
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Suppression pièce jointe
          </Modal.Title>
        </Modal.Header>
        {deleteSuccess !== true ? (
          <Modal.Body>
            <React.Fragment>
              <p className="text-center">
                Etes vous sur de vouloir supprimer la pièce jointe selectionnée
                ? Cette opération sera immediate et ne pourra pas etre annulée !
              </p>

              <div style={{ marginTop: 10 }} className="float-right">
                <Button onClick={props.onHide}>Annuler</Button>

                <Button variant="danger" onClick={() => deletePJ()}>
                  Supprimer
                </Button>
              </div>
            </React.Fragment>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="text-center ">
              {/* <p className="text-success">{message}</p> */}
              <p className="text-success">
                La piece jointe a bien été supprimée !
              </p>
              <MdDelete className="text-success" size={64} />
              <div style={{ marginTop: 20 }}>
                <Button onClick={retourApresDelete}>Retour</Button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }

  return (
    <div className="text-center " style={{ marginLeft: 5 }}>
      <TiDeleteOutline onClick={() => setModalShow(true)} />

      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default DeletePieceJointe;
