import React, { Fragment } from 'react';
import DeletePieceJointe from './DeletePieceJointe';
import download from 'downloadjs';
import axios from 'axios';

const ListePiecesJointes = ({ listPJ, OnChangePieceJointe, tailleTexte }) => {
  const downloadFile = async (id) => {
    try {
      const result = await axios.get(`/api/piecejointe/download/${id}`, {
        responseType: 'blob'
      });
      return download(result.data, result.headers.nom, result.data.type);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // setErrorMsg('Error while downloading file. Try again later');
      }
    }
  };
  return (
    <Fragment>
      {listPJ === undefined || listPJ === null || listPJ.length === 0 ? (
        // <Fragment>{<td></td>}</Fragment>
        <Fragment></Fragment>
      ) : (
        <div>
          <ul className="list-group list-group-flush">
            {listPJ.map((pj, index) => (
              <li className="list-group-item text-center" key={index}>
                <div className="row">
                  {pj.piecejointe &&
                  pj.piecejointe.nom &&
                  pj.piecejointe._id ? (
                    <button
                      type="button"
                      className={
                        tailleTexte === 'small'
                          ? 'link-button-small inline-block'
                          : 'link-button inline-block'
                      }
                      onClick={() => downloadFile(pj.piecejointe._id)}
                    >
                      {pj.piecejointe.nom}
                    </button>
                  ) : (
                    <div></div>
                  )}

                  {OnChangePieceJointe !== undefined &&
                  pj.piecejointe &&
                  pj.piecejointe._id ? (
                    <DeletePieceJointe
                      className="inline-block"
                      idAEffacer={pj.piecejointe._id}
                      addPJ={OnChangePieceJointe}
                      listPJ={listPJ}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default ListePiecesJointes;
