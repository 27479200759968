import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import AffichageDrapeau from '../drapeauValidation/AffichageDrapeau';

const ResultatItem = ({
  index,
  fichegrue: {
    _id: id,
    author: { _id: author_id, name },
    marque,
    modele,
    serie,
    type1,
    type2,
    origine,
    liaison,
    serialnumber,
    anneeprod,
    infovue,
    indexgrue,
    nbs,
    module,
    date,
    typemc600,
    effacable,
    bom,
    valide
  },
  deleteFiche,
  displayFiche602,
  displayFiche603,
  setRefresh
}) => {
  //generation du tableau avec seq en colonne

  const [tableSeqPieces, setTableSeqPieces] = useState([]);

  useEffect(() => {
    var table = [];
    for (let i = 10; i <= 390; i = i + 10) {
      const bomfil = bom.filter((elt) => elt.piece !== null && elt.seq === i);
      if (bomfil.length > 0) {
        table.push(bomfil[0]);
      } else {
        table.push(null);
      }
    }
    setTableSeqPieces(table);
    // console.log(table);
  }, [bom]);

  return (
    <Fragment class="form-row align-items-center">
      {(displayFiche603 === true && typemc600 === 603) ||
      (displayFiche602 === true && typemc600 === 602) ? (
        <tr>
          <td id="th2">
            <AffichageDrapeau valide={valide} ID={id} setRefresh={setRefresh} />
          </td>
          <td id="th0">
            {typemc600 === 603 ? (
              <h4>
                <span class="badge badge-pill badge-success p-2 mb-2 ">
                  603
                </span>
              </h4>
            ) : (
              <h4>
                <span class="badge badge-pill badge-warning mb-0 ">602</span>
              </h4>
            )}
          </td>
          {/* <td id="th6">
            <Link to={`/creation/${id}`}>
              <button className="btn btn-primary ">
                Dupliquer <FaCopy />
              </button>
            </Link>
          </td> */}
          <td id="th7">
            <Link to={`/fichegrue/${id}`}>
              <button className="btn btn-ascorel btn-sm">
                Detail <MdInfoOutline color="white" />
              </button>
            </Link>
          </td>
          <td class="text-center align-middle" id="th1" width="100">
            <Moment format="DD/MM/YYYY">{moment.utc(date)}</Moment>
          </td>
          <td class="text-center align-middle" id="marque">
            {marque}
          </td>
          <td class="text-center align-middle" id="modele">
            {modele}
          </td>
          <td class="text-center align-middle" id="serie">
            {serie}
          </td>
          {effacable === true ? (
            <td id="btn_effac" class="text-center">
              <button className="btn btn-warning" onClick={deleteFiche}>
                <MdDelete />
              </button>
            </td>
          ) : (
            <td id="btn_effac_nope"></td>
          )}
          <td class="text-center align-middle" id="type1">
            {type1}
          </td>
          {/* <td id="type2">{type2}</td> */}
          <td class="text-center align-middle" id="origine">
            {origine}
          </td>
          <td class="text-center align-middle" id="liaison">
            {liaison}
          </td>
          <td class="text-center align-middle" id="serialnumber">
            {serialnumber}
          </td>
          <td class="text-center align-middle" id="anneeprod">
            {anneeprod}
          </td>
          <td class="text-center align-middle" id="infovue">
            {infovue}
          </td>
          <td class="text-center align-middle" id="indexgrue">
            {indexgrue}
          </td>
          <td class="text-center align-middle" id="nbs">
            {nbs}
          </td>
          <td class="text-center align-middle" id="module">
            {module}
          </td>
          <td class="text-center align-middle" id="th4" width="400"></td>
          {tableSeqPieces.length > 0 ? (
            tableSeqPieces.map((elt, index) =>
              elt === null || elt.piece === null ? (
                <td></td>
              ) : (
                <td
                  style={StyleDetail}
                  className="text-center align-middle"
                  id={index}
                >
                  {elt.piece.nom}
                </td>
              )
            )
          ) : (
            <div>Aucune elt trouvé :(...</div>
          )}
        </tr>
      ) : (
        <tr></tr>
      )}
    </Fragment>
  );
};

ResultatItem.propTypes = {
  fichegrue: PropTypes.object.isRequired,
  deleteFiche: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const StyleDetail = {
  fontSize: '10px'
};

export default ResultatItem;
