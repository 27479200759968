import React, { Fragment } from 'react';

const NotFound = () => {
  return (
    <section className="container">
      <Fragment>
        <h1 className="x-large text-primary">
          <i className="fas fa-exclamation-triangle" /> Page inexistante
        </h1>
        {/* <p className='large'>Désolé, il semble qu'une erreur s'est produite</p> */}
        <p className="large">
          SVP noter l'adresse dans la barre du navigateur et la transmettre à TM
        </p>
      </Fragment>
    </section>
  );
};

export default NotFound;
