import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import user from './user';
import fichegrue from './fichegrue';
import optionfichegrue from './optionfichegrue';
import piece from './piece';
import eventlog from './eventlog';

export default combineReducers({
  alert,
  auth,
  user,
  fichegrue,
  optionfichegrue,
  piece,
  eventlog
});
