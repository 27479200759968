import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_PIECE,
  PIECE_ERROR,
  CLEAR_PIECE,
  UPDATE_PIECE,
  GET_PIECES
} from './types';

// Get all pieces
export const getPieces = () => async (dispatch) => {
  dispatch({ type: CLEAR_PIECE });

  try {
    const res = await axios.get('/api/piece');

    dispatch({
      type: GET_PIECES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Piece by ID
export const getPieceById = (pieceId) => async (dispatch) => {
  dispatch({ type: CLEAR_PIECE });

  try {
    const res = await axios.get(`/api/piece/${pieceId}`);

    dispatch({
      type: GET_PIECE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all pieces by seq
export const getPiecesBySeq = (MC600) => async (dispatch) => {
  dispatch({ type: CLEAR_PIECE });

  try {
    const res = await axios.get(`/api/piece/seq/${MC600}`);

    dispatch({
      type: GET_PIECES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all pieces by partial text
export const getPiecesByPartialText = (text) => async (dispatch) => {
  dispatch({ type: CLEAR_PIECE });

  try {
    const res = await axios.get(`/api/piece/partial/${text}`);

    dispatch({
      type: GET_PIECES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update Piece
export const createPiece = (id, formData, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    // const res = await axios.post('/api/piece', formData, config);
    const res = await axios.post(
      id ? `/api/piece/${id}` : '/api/piece',
      formData,
      config
    );

    dispatch({
      type: UPDATE_PIECE,
      payload: res.data
    });

    dispatch(setAlert(id ? 'Piece Updated' : 'Piece Created', 'success'));

    // setTimeout(() => {
    dispatch({ type: CLEAR_PIECE });
    navigate(`/piece/${res.data._id}`);
    // }, 2000);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    if (id) {
      navigate(`/piece/${id}`);
    } else {
      navigate(`/recherchepiece/`);
    }
  }
};

//  delete Option
export const deletePiece = (pieceId) => async (dispatch) => {
  //TODO prevoir traduction anglais
  if (
    window.confirm('Etes vous sur ? Cette opération ne peut pas être annulée !')
  ) {
    try {
      await axios.delete(`/api/piece/${pieceId}`);

      dispatch({ type: CLEAR_PIECE });

      dispatch(setAlert('Piece effacée'));
    } catch (err) {
      dispatch({
        type: PIECE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};

// clearOption
export const clearPiece = () => async (dispatch) => {
  try {
    console.log(CLEAR_PIECE);
    dispatch({ type: CLEAR_PIECE });
  } catch (err) {
    dispatch({
      type: PIECE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
