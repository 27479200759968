import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getEventLog } from '../../actions/eventlog';
import EventItem from './EventItem';

const AffichageLog = ({
  getEventLog,
  eventlog: { event, eventlog, loadinglog }
}) => {
  useEffect(() => {
    getEventLog();
  }, [getEventLog]);

  if (loadinglog) {
    return <Spinner />;
  } else if (eventlog.length === 0) {
    return (
      <Fragment>
        <p>Aucun evenement trouvé</p>
      </Fragment>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              Log Evenements :
            </h4>

            <table
              className="table text-nowrap table-striped table-light table-header-group table-container"
              border="1"
            >
              <thead>
                <tr className="sticky">
                  <th className="text-center " id="th0">
                    index
                  </th>
                  <th className="text-center " id="th1">
                    date
                  </th>
                  <th className="text-center " id="th2">
                    type
                  </th>
                  <th className="text-center" id="th3">
                    comment
                  </th>
                </tr>
              </thead>
              <tbody>
                {eventlog.map((event, index) => (
                  <EventItem event={event} key={event._id} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      </section>
    );
  }
};

AffichageLog.propTypes = {
  getEventLog: PropTypes.func.isRequired,
  eventlog: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  eventlog: state.eventlog
});

export default connect(mapStateToProps, {
  getEventLog
})(AffichageLog);
