import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';

const EventItem = ({ index, event: { _id, type, comment, date } }) => {
  return (
    <Fragment class="form-row align-items-center">
      <tr>
        <td class="text-center align-middle " id="th0" width="100">
          {index}
        </td>
        <td class="text-center align-middle" id="th1">
          <Moment format="DD/MM/YYYY HH:mm:SSS">{moment.utc(date)}</Moment>
        </td>
        <td class="text-center align-middle" id="th2">
          {type}
        </td>
        <td class="text-center align-middle" id="th3">
          {comment}
        </td>
      </tr>
    </Fragment>
  );
};

EventItem.propTypes = {
  event: PropTypes.object.isRequired
};

export default EventItem;
