export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const USER_ERROR = 'USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const USER_DELETED = 'USER_DELETED';
export const GET_FICHEGRUE = 'GET_FICHEGRUE';
export const FICHEGRUE_ERROR = 'FICHEGRUE_ERROR';
export const CLEAR_FICHEGRUE = 'CLEAR_FICHEGRUE';
export const UPDATE_FICHEGRUE = 'UPDATE_FICHEGRUE';
export const GET_FICHESGRUES = 'GET_FICHESGRUES';
export const GET_DISTINCT_MARQUE = 'GET_DISTINCT_MARQUE';
export const GET_DISTINCT_MODELE = 'GET_DISTINCT_MODELE';
export const GET_DISTINCT_TYPE = 'GET_DISTINCT_TYPE';
export const GET_DISTINCT_LIAISON = 'GET_DISTINCT_LIAISON';
export const GET_DISTINCT_CUSTOM = 'GET_DISTINCT_CUSTOM';
export const GET_DISTINCT_REFRESH_All = 'GET_DISTINCT_REFRESH_All';
export const GET_OPTION = 'GET_OPTION';
export const OPTION_ERROR = 'OPTION_ERROR';
export const CLEAR_OPTION = 'CLEAR_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_PIECE = 'GET_PIECE';
export const PIECE_ERROR = 'PIECE_ERROR';
export const CLEAR_PIECE = 'CLEAR_PIECE';
export const UPDATE_PIECE = 'UPDATE_PIECE';
export const GET_PIECES = 'GET_PIECES';
export const GET_EVENT = 'GET_EVENT';
export const EVENT_ERROR = 'EVENT_ERROR';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
