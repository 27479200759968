import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const DuplicationChoixTypeMC600 = ({ auth, match }) => {
  const { id } = useParams();

  return (
    <Fragment>
      <div style={{ marginTop: 50 }}>
        <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
          Creation fiche grue
        </h4>
        <div style={{ marginTop: 50, marginBottom: 50 }} className="row">
          <div className="col text-center">
            {/* <Link to={'/duplication/MC602/0'}>  */}
            <Link to={`/duplication/602/${id}`}>
              <button className="btn btn-warning btn-lg center-block">
                Créer Fiche MC602 <FaArrowRight />
              </button>
            </Link>
          </div>

          <div className="col text-center">
            <Link to={`/duplication/603/${id}`}>
              <button className="btn btn-success btn-lg center-block">
                Créer Fiche MC603 <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DuplicationChoixTypeMC600.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(DuplicationChoixTypeMC600);

const SSStyle = {
  fontSize: '18px',
  background: '#888888',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center'
};
