import React, { Fragment, useState } from 'react';
import { useParams, useMatch, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import logo from '../../img/ascorel.png';
import { updateForgottenPwd } from '../../actions/user';
import Card from 'react-bootstrap/Card';
import { GrCodepen } from 'react-icons/gr';
import { GoVerified } from 'react-icons/go';
import Form from 'react-bootstrap/Form';
import { setAlert } from '../../actions/alert';

const ResetPassword = ({ setAlert, updateForgottenPwd, user: { user } }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { token } = useParams();
  const activation = useMatch(`/activation/${id}/${token}`);

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    acceptCGU: false
  });

  const { password, password2, acceptCGU } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2 || password.length < 6) {
      setAlert(
        'Les mots de passe doivent correspondre et contenir 6 caractères minimum',
        'danger'
      );
    } else {
      if (!acceptCGU && activation) {
        setAlert(
          "Veuillez accepter les conditions générales d'utilisation pour valider votre compte",
          'danger'
        );
      } else {
        setLoading(true);
        if (id && token) updateForgottenPwd(id, token, formData);
      }
    }
  };

  return (
    <section className="container">
      <Fragment>
        {loading && !user ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="parent-flex">
              <div>
                <img src={logo} alt="logo" height="100" />
              </div>
            </div>
            <div>
              {!user ? (
                <Fragment>
                  <Card className="card-style-activate">
                    <GrCodepen
                      size={96}
                      className="icon-center my-3"
                      color="white"
                    />
                    <Card.Body className="col-6">
                      {activation ? (
                        <Card.Title className="card-header-color-title-activate text-center">
                          Pour activer votre compte, veuillez entrer votre
                          nouveau mot de passe et accepter les conditions
                          générales d'utilisation
                        </Card.Title>
                      ) : (
                        <Card.Title className="card-header-color-title-activate text-center">
                          Veuillez entrez votre nouveau mot de passe
                        </Card.Title>
                      )}
                      <Card.Text
                        className="card-header-color text-center"
                        style={{ marginTop: '3rem' }}
                      >
                        <form className="form" onSubmit={(e) => onSubmit(e)}>
                          <div className="form-group">
                            {' '}
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="Mot de passe"
                              value={password}
                              onChange={(e) => onChange(e)}
                            />
                            <small className="form-text-white">
                              Nouveau mot de passe, 6 caractères minimum
                            </small>
                          </div>

                          <div className="form-group">
                            <Form.Control
                              type="password"
                              name="password2"
                              placeholder="Confirmer nouveau mot de passe"
                              value={password2}
                              onChange={(e) => onChange(e)}
                            />
                            <small className="form-text-white">
                              Répéter nouveau de passe
                            </small>
                          </div>
                          {activation ? (
                            <div className="form-group">
                              <div
                                className="checkbox"
                                style={{ marginBottom: '1rem' }}
                              >
                                <Form.Check
                                  className=" col-auto"
                                  defaultChecked={false}
                                  name="acceptCGU"
                                  onClick={(e) =>
                                    onChange({
                                      target: {
                                        name: 'acceptCGU',
                                        value: e.target.checked
                                      }
                                    })
                                  }
                                />
                                <label
                                  className=" col-auto form-text-white"
                                  style={{
                                    marginLeft: '0.5rem',
                                    marginTop: '0.2rem'
                                  }}
                                >
                                  J'accepte les{' '}
                                  <Link to="/cgu" className="link_white">
                                    conditions générales d'utilisation.
                                  </Link>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <Fragment></Fragment>
                          )}

                          <input
                            type="submit"
                            className="btn btn-light"
                            value="Enregistrer"
                          />
                        </form>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Fragment>
              ) : (
                <Fragment>
                  <Card className="card-style-activate">
                    <GoVerified
                      size={96}
                      className="icon-center my-3"
                      color="green"
                    />
                    <Card.Body>
                      {activation ? (
                        <Card.Title className="card-header-color-title-activate text-center">
                          Compte activé, vous pouvez fermer cette page
                        </Card.Title>
                      ) : (
                        <Card.Title className="card-header-color-title-activate text-center">
                          Mot de passe réinitialisé, vous pouvez fermer cette
                          page.
                        </Card.Title>
                      )}
                    </Card.Body>
                  </Card>
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  updateForgottenPwd: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, {
  updateForgottenPwd,
  setAlert
})(ResetPassword);
