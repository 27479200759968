import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 5
  },
  rapportRowContainer: {
    marginTop: 10,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },

  rapportContainerCreationFiche: {
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },

  // infoClient: {
  //   marginTop: 5,
  //   paddingBottom: 3,
  //   fontFamily: 'Helvetica-Oblique',
  //   fontSize: 15
  // },
  rapportTitre: {
    marginTop: 10,
    fontSize: 12,
    fontStyle: 'bold'
  },
  rapportTexte: {
    fontSize: 10
    // fontStyle: 'bold'
  }
});

const RapportFin = ({ rapport }) => (
  <View style={styles.headerContainer}>
    {rapport.fichegrue.commentaire &&
    rapport.fichegrue.commentaire.length > 0 ? (
      <View style={styles.rapportRowContainer}>
        <Text style={styles.rapportTitre}>Commentaires creation fiche :</Text>
        <Text style={styles.rapportTexte}>{rapport.fichegrue.commentaire}</Text>
      </View>
    ) : (
      <View style={styles.rapportRowContainer}></View>
    )}

    {rapport.commentairepdf !== null && rapport.commentairepdf.length > 0 ? (
      <View style={styles.rapportRowContainer}>
        <Text style={styles.rapportTitre}>Commentaire impression fiche :</Text>
        <Text style={styles.rapportTexte}>{rapport.commentairepdf}</Text>
      </View>
    ) : (
      <View style={styles.rapportRowContainer}></View>
    )}

    {/* <Text style={styles.rapportTitre}>Archives :</Text>
    <Text style={styles.rapportTexte}>{rapport.fichegrue.archive}</Text> */}
    <View style={styles.rapportContainerCreationFiche}>
      {rapport.fichegrue.author &&
      rapport.fichegrue.author.name !== 'thomas marmeth' &&
      rapport.commentairepdf.length > 0 ? (
        <Text
          style={{
            fontSize: 12,
            fontStyle: 'bold'
          }}
        >
          Fiche crée par {rapport.fichegrue.author.name} le{' '}
          {rapport.fichegrue.date}
        </Text>
      ) : (
        <Text
          style={{
            fontSize: 12,
            fontStyle: 'bold'
          }}
        >
          Fiche archive MC602 (import automatique) le {rapport.fichegrue.date}
        </Text>
      )}
    </View>
  </View>
);

export default RapportFin;
