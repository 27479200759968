import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ItemDynamicSelect from '../duplication/ItemDynamicSelect';
import { MdDelete } from 'react-icons/md';
import ModalImportPJ from '../duplication/ModalImportPJ';
import ListePiecesJointes from '../duplication/ListePiecesJointes';

const DESIGNATION_LIEN_602 = {
  10: '	Calculateur V2',
  40: "	Manuel d'utilisation",
  50: '	Tableau de bord',
  60: '	Support tableau de bord',
  70: 'Câble tableau de bord',
  80: 'Alimentation 24VDC',
  90: '	Disjoncteur bipolaire 10A',
  100: '	Bouchon shunt',
  110: 'Pré-câblage',
  120: "Capteur d'orientation",
  130: 'Pignon externe',
  140: 'Câble capteur d"orientation',
  150: 'Support 1',
  160: 'Support 2',
  170: 'Fixation câble',
  180: 'Capteur',
  210: 'Connexion',
  230: 'Neutralisation',
  240: 'Signalisation',
  250: 'Connexion',
  260: 'Câble 4/20mA OR-CH',
  320: "Capteur d'angle",
  360: 'Grue sans bague dispo',
  390: 'Support calculateur',
  400: 'Capteur de translation',
  410: 'Radio',
  420: 'Anémomètre',
  430: 'Signalisation',
  440: 'Afficheur géant'
};

const DESIGNATION_LIEN_603 = {
  10: '	Calculateur',
  20: '	Interface grue',
  30: '	Cordon interface grue',
  40: '	Notice utilisation',
  50: '	Tableau de bord',
  60: '	Fixation tableau de bord',
  70: '	Cordon tableau de bord',
  80: '	Alimentation',
  90: '	Disjoncteur bipolaire 10A',
  100: '	Bouchon shunt',
  110: '	Pré-câblage',
  120: "	Capteur d`'orientation",
  130: '	Pignon externe orientation',
  140: '	Câble capteur orientation',
  150: '	Support 1 orientation',
  160: '	Support 2 orientation',
  170: '	Fixation câbles',
  180: '	Capteur distribution',
  190: '	Fixation 1 distribution',
  200: '	Fixation 2 distribution',
  210: '	Câble capteur distribution',
  220: '	Pignon externe distribution',
  230: '	Neutralisation',
  240: '	Signalisation',
  250: '	Connexion neutralisation',
  260: '	Raccordement bus grue',
  270: '	Capteur de hauteur',
  280: '	Fixation 1',
  290: '	Fixation 2',
  300: '	Connexion.',
  310: '	Pignon externe',
  320: '	Capteur.',
  330: '	Connexion',
  340: '	Capteur',
  350: '	Connexion',
  360: '	Radio CAN',
  370: '	Cordon calculateur - radio CAN',
  380: '	Notice neutralisation',
  390: '	Fixation CPI / IG',
  400: '	Capteur de translation',
  410: '	Radio',
  420: '	Anémomètre',
  430: '	Signalisation',
  440: '	Afficheur géant',
  450: '	Alimentation',
  460: '	TDB pied de grue',
  470: '	Modem MyAscorel',
  480: '	Dérivation CAN charge',
  490: '	Dérivation CAN charge',
  500: '	Dérivation CAN hauteur',
  510: '	Dérivation CAN hauteur'
};

const ItemDuplication = ({
  typeMC600,
  duplication,
  eltNew,
  eltOld,
  OnChangeQte,
  OnChangePieceJointe,
  listePieces,
  selectIndex,
  onSelectionList,
  onDeletePiece,
  flagVariante,
  flagNomenclatureBase
}) => {
  if (eltNew.seqEnDouble) {
    console.log('->>' + eltNew.seq);
  }

  return (
    // <Fragment className={eltNew.seqEnDouble ? 'flaggedSeqDouble' : ''}>
    <Fragment>
      {eltNew.piece === undefined || eltNew.piece === null ? (
        <Fragment>{/* <td>erreur</td> */}</Fragment>
      ) : (
        <Fragment>
          <td
            className={
              'text-center align-middle' +
              (eltNew.seqEnDouble ? ' flaggedSeqDouble ' : '')
            }
          >
            {eltNew.seq}
          </td>
          {duplication === false ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              {!eltOld || !eltOld.piece ? (
                <td>aucune correspondance trouvée</td>
              ) : (
                <td class="text-center align-middle">{eltOld.piece.nom}</td>
              )}
            </Fragment>
          )}
          <ItemDynamicSelect
            textDesignation={
              typeMC600 === '602'
                ? DESIGNATION_LIEN_602[eltNew.seq]
                : DESIGNATION_LIEN_603[eltNew.seq]
            }
            flagVariante={flagVariante}
            listePieces={listePieces}
            // selectIndex={listePieces.length === 1 && selectIndex === 0 ? 1 : 0}
            selectIndex={
              listePieces && listePieces.length === 1 ? 1 : selectIndex
            }
            onSelection={onSelectionList}
            eltNew={eltNew}
          />
          <td>
            <input
              type="number"
              min="0"
              className="form-control"
              value={eltNew.qte}
              onChange={OnChangeQte}
            />
          </td>
          {flagNomenclatureBase ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              {/* <td class="text-center">
                <ModalImportPJ
              addPJ={OnChangePieceJointe}
              listPJ={eltNew.piecesjointes}
            />
              </td> */}
              <td class="text-center">
                <ListePiecesJointes
                  OnChangePieceJointe={OnChangePieceJointe}
                  tailleTexte="small"
                  listPJ={eltNew.piecesjointes}
                />
              </td>
            </Fragment>
          )}
          <td class="text-center">
            <button className="btn btn-warning" onClick={onDeletePiece}>
              <MdDelete />
            </button>
          </td>
        </Fragment>
      )}
    </Fragment>
  );
};

// ItemDuplication.propTypes = {
//   piece: PropTypes.object.isRequired
// };

export default ItemDuplication;
