import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  rapportNoContainer: {
    flexDirection: 'row',
    marginTop: 5,
    justifyContent: 'flex-start'
  },
  rapportDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  rapportDate: {
    fontSize: 12,
    fontStyle: 'bold'
  },
  label: {
    // width: 110
  }
});

const RapportNo = ({ rapport }) => (
  <Fragment>
    {/* <View style={styles.rapportNoContainer}>
      <Text style={styles.label}>Identifiant fiche grue :</Text>
      <Text style={styles.rapportDate}>{rapport.fichegrue._id}</Text>
    </View> */}
    <View style={styles.rapportDateContainer}>
      <Text style={styles.label}> Date impression: </Text>
      <Text>{rapport.date_generation}</Text>
    </View>
  </Fragment>
);

export default RapportNo;
