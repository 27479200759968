import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPieceById, createPiece } from '../../actions/piece';
import { MdSave } from 'react-icons/md';
import { MdCancel } from 'react-icons/md';
import ListePiecesJointes from '../duplication/ListePiecesJointes';
import ModalImportPJ from '../duplication/ModalImportPJ';
import Spinner from '../layout/Spinner';
import { setAlert } from '../../actions/alert';
import { useParams, useNavigate } from 'react-router-dom';

const Buffer = require('buffer/').Buffer;

const initialState = {
  nom: '',
  refx3: '',
  commentaire: '',
  typemc600: '603',
  piecesjointes: [],
  seqpossible: '',
  vignette: ''
};

const CreationPiece = ({
  setAlert,
  getPieceById,
  createPiece,
  piece: { piece, loadingpiece },
  auth
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== '0') {
      getPieceById(id);
    }
  }, [getPieceById, id]);

  useEffect(() => {
    if (!loadingpiece && piece) {
      const data = { ...initialState };
      for (const key in data) {
        if (key in data) data[key] = piece[key];
        if (key === 'seqpossible') data[key] = piece[key].join('/');
        if (key === 'vignette') data[key] = ''; // on ne recopie pas vignette pour ne pas surcharger
      }
      setFormDataPiece(data);
    }
  }, [loadingpiece, piece]);

  const onChangeDataPiece = (e, name) => {
    setFormDataPiece({
      ...formDataPiece,
      [name]: e[0]
    });
  };

  const onChangeSrcPreviewVignette = (dt) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(dt.file);
    onChangeDataPiece(dt.data, 'vignette');
  };

  const SauvegarderPiece = () => {
    if (
      formDataPiece.refx3.length !== 9 &&
      formDataPiece.refx3.length !== 10 &&
      formDataPiece.refx3.length !== 11
    ) {
      setAlert('La reference X3 doit faire 9 caractères', 'danger');
    }
    if (formDataPiece.nom.length === 0) {
      setAlert('Veuillez entrer un nom pour la pièce svp', 'danger');
    }
    if (formDataPiece.seqpossible.length === 0) {
      setAlert('Veuillez entrer une sequence pour la pièce svp', 'danger');
    }

    if (
      (formDataPiece.refx3.length === 9 ||
        formDataPiece.refx3.length === 10 ||
        formDataPiece.refx3.length === 11) &&
      formDataPiece.nom.length !== 0 &&
      formDataPiece.seqpossible.length !== 0
    ) {
      if (
        window.confirm('Etes vous sur de vouloir sauvegarder cette pièce ?')
      ) {
        setaction(true);
        const clone = {
          ...formDataPiece,
          seqpossible: formDataPiece.seqpossible.split('/').map(Number)
        };

        createPiece(id !== '0' ? id : null, clone, navigate);
      }
    }
  };

  const [formDataPiece, setFormDataPiece] = useState(initialState);
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage

  useEffect(() => {
    console.log(formDataPiece);
  }, [formDataPiece]);

  const [action, setaction] = useState(false);
  console.log(id);
  console.log('loadingpiece' + loadingpiece);

  if (
    (loadingpiece && id !== '0') ||
    action ||
    (formDataPiece.refx3 === '' && id !== '0')
  ) {
    return <Spinner />;
  } else {
    let base64ImageString;
    if (piece && piece.vignette) {
      base64ImageString = Buffer.from(
        piece.vignette.img.data.data,
        'binary'
      ).toString('base64');
    }
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50 }}>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              {id !== '0' ? 'Modification pièce' : 'Creation pièce'}
            </h4>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              {/* <form> */}
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Nom:
                </label>
                <div class="col-10">
                  <input
                    type="text"
                    className="form-control "
                    value={formDataPiece.nom}
                    onChange={(e) => onChangeDataPiece([e.target.value], 'nom')}
                    placeholder="Nom"
                  />
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Reference X3:
                </label>
                <div class="col-10">
                  <input
                    type="text"
                    className="form-control "
                    value={formDataPiece.refx3}
                    onChange={(e) =>
                      onChangeDataPiece([e.target.value], 'refx3')
                    }
                    placeholder="Reference X3"
                  />
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Sequence(s):
                </label>
                <div class="col-10">
                  <input
                    type="text"
                    className="form-control "
                    value={formDataPiece.seqpossible}
                    onChange={(e) =>
                      onChangeDataPiece([e.target.value], 'seqpossible')
                    }
                    placeholder="Sequences, separées par des '/' si plusieurs possibles"
                  />
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Type:
                </label>
                <div class="col-10">
                  <select
                    name="status"
                    value={formDataPiece.typemc600}
                    className="form-control "
                    onChange={(e) =>
                      onChangeDataPiece([e.target.value], 'typemc600')
                    }
                  >
                    <option value="603">MC603</option>
                    <option value="602">MC602</option>
                  </select>
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Commentaire:
                </label>
                <div class="col-10">
                  <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Renseigner vos commentaires ici"
                    type="text"
                    name="commentaire"
                    value={formDataPiece.commentaire}
                    onChange={(e) =>
                      onChangeDataPiece([e.target.value], 'commentaire')
                    }
                  ></textarea>
                </div>
              </form>
              <form class="row col-12">
                <label style={labelStyle} class="col-2">
                  Vignette:
                </label>
                <div class="row col-5">
                  {piece && piece.vignette && previewSrc === '' ? (
                    <div style={{ marginTop: 10 }} className="image-preview">
                      <img
                        className="vignette-detail"
                        src={`data:${piece.vignette.img.contentType};base64,${base64ImageString}`}
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <div>
                      {previewSrc ? (
                        <div
                          style={{ marginTop: 10 }}
                          className="image-preview"
                        >
                          <img
                            className="vignette-detail"
                            src={previewSrc}
                            alt="Preview"
                          />
                        </div>
                      ) : (
                        <div style={{ marginTop: 10 }}>
                          {/* <ModalImportPJ
                          addPJ={(vg) => onChangeSrcPreviewVignette(vg)}
                          // listPJ={formDataPiece.piecesjointes}
                          importVignette={true}
                        /> */}
                        </div>
                      )}
                    </div>
                  )}
                  <div style={{ marginTop: 10 }} class="col-5">
                    <ModalImportPJ
                      addPJ={(vg) => onChangeSrcPreviewVignette(vg)}
                      // listPJ={formDataPiece.piecesjointes}
                      importVignette={true}
                    />
                  </div>
                </div>
              </form>
              {/* </form> */}
            </div>
          </div>

          <Fragment>
            <div className="row" style={{ marginLeft: 0 }}>
              <div className="card" style={{ width: '500px' }}>
                <div className="card-header" style={headStyle}>
                  Pièces jointes :
                </div>
                <ListePiecesJointes
                  OnChangePieceJointe={(list) =>
                    onChangeDataPiece([list], 'piecesjointes')
                  }
                  tailleTexte="normal"
                  listPJ={formDataPiece.piecesjointes}
                />
              </div>
              <div style={{ margin: 10 }}>
                <ModalImportPJ
                  addPJ={(list) => onChangeDataPiece([list], 'piecesjointes')}
                  listPJ={formDataPiece.piecesjointes}
                />
              </div>
            </div>
          </Fragment>

          <div style={{ marginTop: 50 }} className="row ">
            <div className="col-12 text-center ">
              <button
                // className="btn btn-warning btn-lg float-rigth"
                className="btn btn-success btn-lg"
                onClick={SauvegarderPiece}
              >
                Sauvegarder piece <MdSave />
              </button>

              <button
                className="btn btn-secondary btn-lg"
                onClick={() => navigate(-1)}
              >
                Annuler <MdCancel />
              </button>
            </div>
          </div>
        </Fragment>
      </section>
    );
  }
};

CreationPiece.propTypes = {
  auth: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  createPiece: PropTypes.func.isRequired,
  getPieceById: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  piece: state.piece,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPieceById,
  createPiece,
  setAlert
})(CreationPiece);

const headStyle = {
  fontSize: '20px',
  background: '#333355',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};

const labelStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 10
};
