import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_FICHEGRUE,
  FICHEGRUE_ERROR,
  CLEAR_FICHEGRUE,
  UPDATE_FICHEGRUE,
  GET_FICHESGRUES,
  GET_DISTINCT_MARQUE,
  // GET_DISTINCT_MODELE,
  // GET_DISTINCT_TYPE,
  // GET_DISTINCT_LIAISON,
  // GET_DISTINCT_CUSTOM,
  GET_DISTINCT_REFRESH_All
} from './types';

// Get all Fichesgrues
export const getFichesgrues = () => async (dispatch) => {
  dispatch({ type: CLEAR_FICHEGRUE });

  try {
    const res = await axios.get('/api/fichegrue');

    dispatch({
      type: GET_FICHESGRUES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Fichegrue by ID
export const getFichegrueById = (grueId, vignette) => async (dispatch) => {
  dispatch({ type: CLEAR_FICHEGRUE });

  try {
    const res = await axios.get(`/api/fichegrue/id/${grueId}`, {
      params: {
        vignette: vignette
      }
    });
    dispatch({
      type: GET_FICHEGRUE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Fichegrue by parameter type grue
// export const getFichegrueByParameter = (grueModele) => async dispatch => {
// export const getFichegrueByParameter = (
//   grueMarque,
//   grueModele,
//   grueType,
//   grueLiaison
// ) => async (dispatch) => {
//   dispatch({ type: CLEAR_FICHEGRUE });

//   try {
//     const res = await axios.get(
//       `/api/fichegrue/grue/${grueMarque}/${grueModele}/${grueType}/${grueLiaison}`
//     );

//     dispatch({
//       type: GET_FICHESGRUES,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: FICHEGRUE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// Get Fichegrue by variable parameters, custom search
export const getFichegrueByCustomParameters =
  (
    marque,
    modele,
    serie,
    type1,
    type2,
    origine,
    liaison,
    serialnumber,
    anneeprod,
    infovue,
    indexgrue,
    nbs
  ) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_FICHEGRUE });

    try {
      const res = await axios.get(`/api/fichegrue/grue/custom`, {
        params: {
          marque: marque,
          modele: modele,
          serie: serie,
          type1: type1,
          type2: type2,
          origine: origine,
          liaison: liaison,
          serialnumber: serialnumber,
          anneeprod: anneeprod,
          infovue: infovue,
          indexgrue: indexgrue,
          nbs: nbs
        }
      });

      dispatch({
        type: GET_FICHESGRUES,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: FICHEGRUE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// Get all nomenclatures de base
export const getAllNomenclatureBase = () => async (dispatch) => {
  dispatch({ type: CLEAR_FICHEGRUE });

  try {
    const res = await axios.get('/api/fichegrue/grue/nombase');

    dispatch({
      type: GET_FICHESGRUES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get list of all marque
export const getDistinctRefreshAll =
  (
    marque,
    modele,
    serie,
    type1,
    type2,
    origine,
    liaison,
    serialnumber,
    anneeprod,
    infovue,
    indexgrue,
    nbs,
    module
  ) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_FICHEGRUE });
    // console.log('getDistinctRefreshAll');
    try {
      const res = await axios.get(`/api/fichegrue/refresh_all_distinct`, {
        params: {
          marque: marque,
          modele: modele,
          serie: serie,
          type1: type1,
          type2: type2,
          origine: origine,
          liaison: liaison,
          serialnumber: serialnumber,
          anneeprod: anneeprod,
          infovue: infovue,
          indexgrue: indexgrue,
          nbs: nbs,
          module: module
        }
      });

      dispatch({
        type: GET_DISTINCT_REFRESH_All,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: FICHEGRUE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// Get list of all marque
export const getDistinctMarque = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/fichegrue/distinct/marque`);

    dispatch({
      type: GET_DISTINCT_MARQUE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update Fichegrue
export const createFichegrue =
  (id, formData, navigate, drapeau = false, nomenclature = false) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_FICHEGRUE });

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(
        id ? `/api/fichegrue/${id}` : '/api/fichegrue',
        formData,
        config
      );

      dispatch({
        type: UPDATE_FICHEGRUE,
        payload: res.data
      });

      dispatch(
        setAlert(id ? 'Fichegrue mise à jour' : 'Fichegrue Crée', 'success')
      );

      if (nomenclature) {
        navigate(`/listnombase`);
      } else if (drapeau) {
      } else {
        navigate(`/fichegrue/${res.data._id}`);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));

          dispatch({
            type: FICHEGRUE_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status
            }
          });
        }
      }
    }
  };

// Delete Fichegrue
export const deleteFichegrue = (grueId) => async (dispatch) => {
  //TODO prevoir traduction anglais
  // if (
  //   window.confirm('Etes vous sur ? Cette opération ne peut pas être annulée !')
  // ) {
  try {
    await axios.delete(`/api/fichegrue/${grueId}`);

    dispatch({ type: CLEAR_FICHEGRUE });

    dispatch(
      setAlert('La fiche grue à été effacée de manière definitive', 'danger')
    );
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  // }
};

// Delete Fichegrue
export const clearFichegrue = () => async (dispatch) => {
  try {
    console.log(CLEAR_FICHEGRUE);
    dispatch({ type: CLEAR_FICHEGRUE });
  } catch (err) {
    dispatch({
      type: FICHEGRUE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
