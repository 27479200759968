import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import Moment from 'react-moment';
import moment from 'moment';

const ResultatOptionItem = ({
  index,
  option: {
    _id: id,
    nom,
    refx3,
    commentaire,
    datecreation,
    datemodification,
    typemc600,
    deleted
  },
  displayOption602,
  displayOption603,
  displayOptionDeleted
}) => {
  return (
    <Fragment class="form-row align-items-center">
      {((displayOption603 === true && typemc600 === 603) ||
        (displayOption602 === true && typemc600 === 602)) &&
      !(displayOptionDeleted === false && deleted === true) ? (
        <tr>
          <td id="th0">
            {typemc600 === 603 ? (
              <h4>
                <span class=" text-center align-middle  badge badge-pill badge-success ">
                  603
                </span>
              </h4>
            ) : (
              <h4>
                <span class="text-center align-middle badge badge-pill badge-warning">
                  602
                </span>
              </h4>
            )}
          </td>
          <td id="th7">
            <Link to={`/option/${id}`}>
              <button className="btn btn-ascorel btn-sm">
                Detail <MdInfoOutline color="white" />
              </button>
            </Link>
          </td>

          <td
            class="text-center align-middle "
            id="marque"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {nom}
          </td>
          <td
            class="text-center align-middle"
            id="modele"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {refx3}
          </td>
          {/* {datecreation !== undefined ? (
            <td
              class="text-center align-middle"
              id="th1"
              width="100"
              style={{
                textDecorationLine: deleted ? 'line-through' : ''
              }}
            >
              <Moment format="DD/MM/YYYY HH:mm">
                {moment.utc(datecreation)}
              </Moment>
            </td>
          ) : (
            <td></td>
          )} */}
          {datemodification !== undefined ? (
            <td
              class="text-center align-middle"
              id="th1"
              width="100"
              style={{
                textDecorationLine: deleted ? 'line-through' : ''
              }}
            >
              <Moment format="DD/MM/YYYY HH:mm">
                {moment.utc(datemodification)}
              </Moment>
            </td>
          ) : (
            <td></td>
          )}
          <td
            class="text-center align-middle"
            id="serie"
            style={{
              textDecorationLine: deleted ? 'line-through' : ''
            }}
          >
            {commentaire}
          </td>
        </tr>
      ) : (
        <tr></tr>
      )}
    </Fragment>
  );
};

ResultatOptionItem.propTypes = {
  piece: PropTypes.object.isRequired
};

export default ResultatOptionItem;
