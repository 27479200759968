import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ffffff';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    // borderBottomColor: '#bff0fd',
    // backgroundColor: '#bff0fd',
    borderBottomColor: '#000000',
    backgroundColor: '#000000',
    color: '#ffffff',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  nom: {
    width: '40%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  commentaire: {
    width: '25%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  refx3: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  },
  qte: {
    width: '5%'
  },
  seq: {
    width: '5%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  }
});

const RapportTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.seq}>Seq</Text>
    <Text style={styles.nom}>Designation</Text>
    <Text style={styles.commentaire}>Note</Text>
    <Text style={styles.refx3}>Reference X3</Text>
    <Text style={styles.qte}>Qté</Text>
    <Text style={styles.qte}>Var</Text>
  </View>
);

export default RapportTableHeader;
