import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ItemDynamicSelect = ({
  listePieces,
  selectIndex,
  onSelection,
  flagVariante,
  eltNew,
  textDesignation
}) => {
  const listVide = ['Liste vide'];

  function creationDynamicSelect(list) {
    let items = [];
    if (selectIndex === 0) {
      items.push(
        <option selected disabled hidden value="">
          Choisir {textDesignation}
        </option>
      );
    }
    for (let i = 0; i < list.length; i++) {
      if (selectIndex !== 0 && i + 1 === selectIndex) {
        items.push(
          <option selected key={i} value={list[i].refx3}>
            {list[i].refx3} - {list[i].nom}
          </option>
        );
      } else {
        items.push(
          <option key={i} value={list[i].refx3}>
            {list[i].refx3} - {list[i].nom}
          </option>
        );
      }
    }
    return items;
  }

  // function handleSelectChange(event) {
  //   onSelection(event.target.value);
  //   // selection = event.target.value;
  //   console.log(event.target.value);
  // }

  return (
    <Fragment>
      {listePieces === undefined || listePieces.length === 0 ? (
        <Fragment>
          <td>
            {/* <select class="form-control  bg-warning"> */}
            <select class="form-control form-select text-detail">
              {creationDynamicSelect(listVide)}
            </select>
          </td>
        </Fragment>
      ) : (
        <Fragment>
          {flagVariante && eltNew.piece.refx3 === '' ? (
            <td>
              <select
                class="form-control form-select bg-warning text-detail"
                onClick={onSelection}
              >
                {creationDynamicSelect(listePieces)}
              </select>
            </td>
          ) : (
            <td>
              <select
                class="form-control form-select text-detail"
                style={{ height: '2.5rem' }}
                onClick={onSelection}
              >
                {creationDynamicSelect(listePieces)}
              </select>
            </td>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ItemDynamicSelect.propTypes = {
  listePieces: PropTypes.object.isRequired
};

export default ItemDynamicSelect;
