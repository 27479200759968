import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import ResultatsRecherche from './components/resultats/ResultatsRecherche';
import DetailFicheGrue from './components/fichegrue/DetailFicheGrue';
import DuplicationFicheGrue from './components/duplication/DuplicationFicheGrue';
import CreateModifNomenclatureBase from './components/nomenclaturebase/CreateModifNomenclatureBase';
import ListNomenclatureBase from './components/nomenclaturebase/ListNomenclatureBase';
import DuplicationChoixTypeMC600 from './components/duplication/DuplicationChoixTypeMC600';
import RechercheFichegrue from './components/recherche/RechercheFichegrue';
import RecherchePiece from './components/piece/RecherchePiece';
import ResultatsRecherchePiece from './components/piece/ResultatsRecherchePiece';
import DetailPiece from './components/piece/DetailPiece';
import CreationPiece from './components/piece/CreationPiece';
import RechercheOption from './components/option/RechercheOption';
import ResultatsRechercheOption from './components/option/ResultatsRechercheOption';
import DetailOption from './components/option/DetailOption';
import CreationOption from './components/option/CreationOption';
import AffichageLog from './components/log/AffichageLog';
import VerifyEmailResetPassword from './components/activation/VerifyEmailResetPassword';
import ResetPassword from './components/activation/ResetPassword';
import CGUacg from './components/activation/CGUacg';
import Userlist from './components/users/UserList';
import UserDetail from './components/users/UserDetail';
import UserEdit from './components/users/UserEdit';
import { LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // obligatoire pour faire fonctionner les popper, modal et dropdown
import './App.css';

const App = () => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/activation/:id/:token" element={<ResetPassword />} />
          <Route path="/verifyemail" element={<VerifyEmailResetPassword />} />
          <Route path="/resetpassword/:id/:token" element={<ResetPassword />} />
          <Route path="/cgu" element={<CGUacg />} />
          <Route
            path="/users"
            element={<PrivateRoute component={Userlist} />}
          />
          <Route
            path="/user/:id"
            element={<PrivateRoute component={UserDetail} />}
          />
          <Route
            path="/edit-user/:id"
            element={<PrivateRoute component={UserEdit} />}
          />
          <Route
            path="/create-user"
            element={<PrivateRoute component={UserEdit} />}
          />
          <Route
            path="/recherche"
            element={<PrivateRoute component={RechercheFichegrue} />}
          />
          <Route
            path="/recherchepiece"
            element={<PrivateRoute component={RecherchePiece} />}
          />
          <Route
            path="/rechercheoption"
            element={<PrivateRoute component={RechercheOption} />}
          />
          <Route
            path="/resultatspiece/:partial"
            element={<PrivateRoute component={ResultatsRecherchePiece} />}
          />
          <Route
            path="/resultatsoption/:partial"
            element={<PrivateRoute component={ResultatsRechercheOption} />}
          />
          <Route
            path="/resultats/:marque/:modele"
            element={<PrivateRoute component={ResultatsRecherche} />}
          />
          {/* <Route
            path="/resultats/(marque)?/:marque?/(modele)?/:modele?/(serie)?/:serie?/(type1)?/:type1?/(type2)?/:type2?/(origine)?/:origine?/(liaison)?/:liaison?/(serialnumber)?/:serialnumber?/(anneeprod)?/:anneeprod?/(infovue)?/:infovue?/(indexgrue)?/:indexgrue?/(nbs)?/:nbs?/"
            element={<PrivateRoute component={ResultatsRecherche} />}
          /> */}
          <Route
            path="/fichegrue/:id"
            element={<PrivateRoute component={DetailFicheGrue} />}
          />
          <Route
            path="/piece/:id"
            element={<PrivateRoute component={DetailPiece} />}
          />
          <Route
            path="/option/:id"
            element={<PrivateRoute component={DetailOption} />}
          />
          <Route
            path="/creationpiece/:id"
            element={<PrivateRoute component={CreationPiece} />}
          />
          <Route
            path="/creationoption/:id"
            element={<PrivateRoute component={CreationOption} />}
          />
          <Route
            path="/creation/:id"
            element={<PrivateRoute component={DuplicationChoixTypeMC600} />}
          />
          <Route
            path="/duplication/:typeMC600/:id"
            element={<PrivateRoute component={DuplicationFicheGrue} />}
          />
          <Route
            path="/modificationfichegrue/:typeMC600/:id/:modification"
            element={<PrivateRoute component={DuplicationFicheGrue} />}
          />
          <Route
            path="/nomenclaturebase/:typeMC600/:id"
            element={<PrivateRoute component={CreateModifNomenclatureBase} />}
          />
          <Route
            path="/listnombase"
            element={<PrivateRoute component={ListNomenclatureBase} />}
          />
          <Route
            path="/eventlog"
            element={<PrivateRoute component={AffichageLog} />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
