import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { FaPrint } from 'react-icons/fa';
import check from '../../img/Check_green_icon.svg.png';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10
  },
  rapportRowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  infoClient: {
    marginTop: 0,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique',
    fontSize: 15
  },
  rapportTitre: {
    fontSize: 12,
    fontStyle: 'bold'
  },
  rapportlabel: {
    // width: 110
  },
  check: {
    width: 50,
    height: 50
  },
  container: {
    // flex: 1,
    flexDirection: 'row'
  },
  leftColumn: {
    flexDirection: 'column',
    width: 250,
    paddingRight: 20
  }
});

const ItemCheckbox = ({ checked, name }) => {
  if (checked === true) {
    return (
      <Fragment>
        <Image style={styles.logo} src={check} />
        <Text style={{ marginRight: 20, marginTop: 5 }}>{name}</Text>
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

const RapportClient = ({ rapport }) => (
  <Fragment>
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <View style={styles.headerContainer}>
          <Text style={styles.infoClient}>Informations client:</Text>
          <Text>{rapport.client}</Text>
          <Text>{rapport.adresse}</Text>
          <Text>
            {rapport.codepostal} {rapport.ville}
          </Text>
          <Text>{rapport.phone}</Text>
          <Text>{rapport.email}</Text>
        </View>
      </View>
      <View style={styles.headerContainer}>
        <Text style={styles.infoClient}>Informations grue:</Text>
        {rapport.fichegrue.marque !== null ? (
          <View style={styles.rapportRowContainer}>
            <Text style={styles.rapportlabel}>Marque :</Text>
            <Text style={styles.rapportTitre}>{rapport.fichegrue.marque}</Text>
          </View>
        ) : (
          <View style={styles.rapportRowContainer}></View>
        )}
        {rapport.fichegrue.modele !== null ? (
          <View style={styles.rapportRowContainer}>
            <Text style={styles.rapportlabel}>Modele :</Text>
            <Text style={styles.rapportTitre}>{rapport.fichegrue.modele}</Text>
          </View>
        ) : (
          <View style={styles.rapportRowContainer}></View>
        )}
        {rapport.fichegrue.serialnumber &&
        rapport.fichegrue.serialnumber.length > 0 ? (
          <View style={styles.rapportRowContainer}>
            <Text style={styles.rapportlabel}>n° Serie :</Text>
            <Text style={styles.rapportTitre}>
              {rapport.fichegrue.serialnumber}
            </Text>
          </View>
        ) : (
          <View style={styles.rapportRowContainer}></View>
        )}
      </View>
    </View>

    {/* <View style={{ marginTop: 20, flexDirection: 'row' }}>
      <Text style={{ fontSize: 12 }}>
        Code tête nomenclature commerciale :{' '}
      </Text>
      <Text style={styles.rapportTitre}> ???</Text>
    </View> */}
  </Fragment>
);

export default RapportClient;
