import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import ItemRapportTableRow from './ItemRapportTableRow';

const borderColor = '#000000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    // height: 15,
    padding: 1,
    fontStyle: 'bold'
  },
  rowsstitre: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    backgroundColor: '#aaaaaa',
    color: '#ffffff'
  },
  textsstitre: {
    width: '100%',
    textAlign: 'center',
    paddingLeft: 8
  },
  // designation: {
  //   width: '40%',
  //   textAlign: 'left',
  //   borderRightColor: borderColor,
  //   borderRightWidth: 1,
  //   paddingLeft: 8
  // },
  commentaire: {
    width: '30%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8
  },
  refx3: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 7
  },
  qte: {
    width: '5%',
    textAlign: 'right',
    paddingRight: 8
  },
  seq: {
    width: '5%',

    textAlign: 'right',
    paddingRight: 8
  }
});

// const InvoiceTableRow = ({ items }) => {
//   const rows = items.map((item) => (
//     <View style={styles.row} key={item.sno.toString()}>
//       <Text style={styles.description}>{item.desc}</Text>
//       <Text style={styles.qty}>{item.qty}</Text>
//       <Text style={styles.rate}>{item.rate}</Text>
//       <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text>
//     </View>
//   ));
//   return <Fragment>{rows}</Fragment>;
// };

const RapportTableRow = ({ items, options, nomcom }) => {
  const CPU_Interface =
    items.length > 0 ? (
      items.map((elt) =>
        elt.piece === null || elt.seq > 110 ? (
          <Fragment></Fragment>
        ) : (
          <View style={styles.row}>
            <ItemRapportTableRow item={elt} />
          </View>
        )
      )
    ) : (
      <Text>!</Text>
    );

  const Orientation =
    items.length > 0 ? (
      items.map((elt) =>
        elt.piece === null || elt.seq > 170 || elt.seq < 120 ? (
          <Fragment></Fragment>
        ) : (
          <View style={styles.row}>
            <ItemRapportTableRow item={elt} />
          </View>
        )
      )
    ) : (
      <Text>!</Text>
    );

  const Distribution =
    items.length > 0 ? (
      items.map((elt) =>
        elt.piece === null || elt.seq < 180 || elt.seq > 220 ? (
          <Fragment></Fragment>
        ) : (
          <View style={styles.row}>
            <ItemRapportTableRow item={elt} />
          </View>
        )
      )
    ) : (
      <Text>!</Text>
    );

  const Divers =
    items.length > 0 ? (
      items.map((elt) =>
        elt.piece === null || elt.seq < 230 ? (
          <Fragment></Fragment>
        ) : (
          <View style={styles.row}>
            <ItemRapportTableRow item={elt} />
          </View>
        )
      )
    ) : (
      <Text></Text>
    );

  const optionsPDFDetail = (list) => {
    return list.length > 0 ? (
      list.map((elt) =>
        elt.piece === null ? (
          <Fragment></Fragment>
        ) : (
          <View style={styles.row}>
            <ItemRapportTableRow item={elt} />
          </View>
        )
      )
    ) : (
      <Text></Text>
    );
  };

  const optionsPDF =
    options.length > 0 ? (
      options.map((elt) =>
        elt.util !== true || elt.bom === null ? (
          <Fragment></Fragment>
        ) : (
          <Fragment>
            <View style={styles.rowsstitre}>
              {/* <Text style={styles.seq}>OPT</Text> */}
              <Text style={styles.textsstitre}>OPTION - {elt.nom}</Text>
              {/* <Text style={styles.commentaire}>{elt.commentaire}</Text> */}
              {/* <Text style={styles.refx3}>{elt.refx3}</Text> */}
              {/* <Text style={styles.qte}></Text> */}
            </View>
            {optionsPDFDetail(elt.bom)}
          </Fragment>
        )
      )
    ) : (
      <Text>!</Text>
    );

  return (
    <Fragment>
      {nomcom !== undefined && nomcom.length > 0 ? (
        <View style={styles.rowsstitre}>
          <Text style={styles.textsstitre}>Nomenclature com. : {nomcom}</Text>
        </View>
      ) : (
        <View></View>
      )}
      <View style={styles.rowsstitre}>
        <Text style={styles.textsstitre}>CPU/Interface</Text>
      </View>
      {CPU_Interface}
      {/* <View break style={styles.rowsstitre}> */}
      <View style={styles.rowsstitre}>
        {/* <Text style={styles.seq}></Text> */}
        <Text style={styles.textsstitre}>Orientation</Text>
        {/* <Text style={styles.commentaire}></Text> */}
        {/* <Text style={styles.refx3}></Text> */}
        {/* <Text style={styles.qte}></Text> */}
      </View>
      {Orientation}
      <View style={styles.rowsstitre}>
        {/* <Text style={styles.seq}></Text> */}
        <Text style={styles.textsstitre}>Distribution</Text>
        {/* <Text style={styles.commentaire}></Text> */}
        {/* <Text style={styles.refx3}></Text> */}
        {/* <Text style={styles.qte}></Text> */}
      </View>
      {Distribution}
      <View style={styles.rowsstitre}>
        {/* <Text style={styles.seq}></Text> */}
        <Text style={styles.textsstitre}>Divers</Text>
        {/* <Text style={styles.commentaire}></Text> */}
        {/* <Text style={styles.refx3}></Text> */}
        {/* <Text style={styles.qte}></Text> */}
      </View>
      {Divers}
      {optionsPDF}
    </Fragment>
  );
};

// const RapportTableRow = ({ items }) => {
//   return (
//     <Fragment>
//       <View style={styles.rowsstitre}>
//         <Text style={styles.textsstitre}>CPU/Interface</Text>
//         <Text style={styles.commentaire}></Text>
//         <Text style={styles.refx3}></Text>
//         <Text style={styles.qte}></Text>
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Calculateur</Text>
//         <ItemRapportTableRow item={items.cpu} qty={items.cpuqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Interface grue</Text>
//         <ItemRapportTableRow item={items.cpusup} qty={items.cpusupqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Cordon interface grue</Text>
//         <ItemRapportTableRow item={items.cablegrue} qty={items.cablegrueqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Notice utilisation</Text>
//         <Text style={styles.commentaire}>dev en cours</Text>
//         <Text style={styles.refx3}>xxxxxxxxxxx</Text>
//         <Text style={styles.qte}>0</Text>
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Afficheur</Text>
//         <ItemRapportTableRow item={items.tdb} qty={items.tdbqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Fixation tableau de bord</Text>
//         <Text style={styles.commentaire}>dev en cours</Text>
//         <Text style={styles.refx3}>xxxxxxxxxxx</Text>
//         <Text style={styles.qte}>0</Text>
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Cordon tableau de bord</Text>
//         <ItemRapportTableRow item={items.tdbcable} qty={items.tdbcableqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Alimentation</Text>
//         <ItemRapportTableRow item={items.alim} qty={items.alimqty} />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Disjoncteur bipolaire 10A</Text>
//         <ItemRapportTableRow
//           item={items.disjoncteur}
//           qty={items.disjoncteurqty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Bouchon shunt</Text>
//         <ItemRapportTableRow
//           item={items.cablebouchon}
//           qty={items.cablebouchonqty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Pré-câblage</Text>
//         <ItemRapportTableRow item={items.cablegrue} qty={items.cablegrueqty} />
//       </View>
//       <View break style={styles.rowsstitre}>
//         <Text style={styles.textsstitre}>Orientation</Text>
//         <Text style={styles.commentaire}></Text>
//         <Text style={styles.refx3}></Text>
//         <Text style={styles.qte}></Text>
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Capteur d'orientation</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient1}
//           qty={items.orientation.orient1qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Pignon externe</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient2}
//           qty={items.orientation.orient2qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Câble capteur orientation</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient3}
//           qty={items.orientation.orient3qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Support codeur</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient4}
//           qty={items.orientation.orient4qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Plaque deport</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient5}
//           qty={items.orientation.orient5qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>Fixation câbles</Text>
//         <ItemRapportTableRow
//           item={items.orientation.orient6}
//           qty={items.orientation.orient6qty}
//         />
//       </View>

//       <View style={styles.rowsstitre}>
//         <Text style={styles.textsstitre}>Distribution</Text>
//         <Text style={styles.commentaire}></Text>
//         <Text style={styles.refx3}></Text>
//         <Text style={styles.qte}></Text>
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>CAPTEUR DE DISTRI. REDONDANT</Text>
//         <ItemRapportTableRow
//           item={items.distribution.distrib1}
//           qty={items.distribution.distrib1qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>PETIT SUPPORT CODEUR</Text>
//         <ItemRapportTableRow
//           item={items.distribution.distrib2}
//           qty={items.distribution.distrib2qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>PLAQUE DE DEPORT</Text>
//         <ItemRapportTableRow
//           item={items.distribution.distrib3}
//           qty={items.distribution.distrib3qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>CORDON M12</Text>
//         <ItemRapportTableRow
//           item={items.distribution.distrib4}
//           qty={items.distribution.distrib4qty}
//         />
//       </View>
//       <View style={styles.row}>
//         <Text style={styles.designation}>PIGNON</Text>
//         <Text style={styles.commentaire}>dev en cours</Text>
//         <Text style={styles.refx3}>xxxxxxxxxxx</Text>
//         <Text style={styles.qte}>0</Text>
//       </View>
//       <View style={styles.rowsstitre}>
//         <Text style={styles.textsstitre}>Kit EU/Neutralisation</Text>
//         <Text style={styles.commentaire}></Text>
//         <Text style={styles.refx3}></Text>
//         <Text style={styles.qte}></Text>
//       </View>
//     </Fragment>
//   );
// };

export default RapportTableRow;
